import React, { useState, useEffect, useRef } from "react";
import useOnScreen from "../hooks/useOnScreen";
import useIsMobileScreen from "../hooks/useIsMobileScreen";

const MemeCard = (props) => {
  const { meme } = props;

  return(
  	<div className="meme-wrapper">
	  	<div className="meme-item" style={{backgroundImage: `url(${meme.image_url})`}}>
	  	</div>
	  	<div className="grid-x grid-margin-x align-middle meme-footer">
	  		<div className="cell medium-6 small-6">
	  			<a  href={`/koteta/${meme.kitten_identifier}`} className="button large no-margin">Осинови</a>
  			</div>
  			<div className="cell medium-6 small-6 text-right">
					<a href={`/koteta/${meme.kitten_identifier}/memes/${meme.id}`} className="button secondary hollow large no-margin">Сподели</a>
  			</div>
  		</div>
  	</div>
  )
}

const MemeList = (props) => {
	const [memes, setMemes] = useState(props.memes.map(m => JSON.parse(m)));
	const [page, setPage] = useState(2);

	const bottomRef = useRef();
  const isVisible = useOnScreen(bottomRef);
  const isMobile = useIsMobileScreen();

  // once BottomRef is visible update page count
	useEffect(() => {
		if (bottomRef.current && isVisible) {
		  setPage(page + 1)
		}
	}, [isVisible]);

 // on page count update fetch more memes
	useEffect(() => {
		fetchMemes()
	}, [page]);


	const fetchMemes = () => {
		let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
    return fetch(`/memes/async_load?page=${page}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
    })
      .then((response) => response.json())
      .then((data) => {
      	let parsed_memes = data.map(k => JSON.parse(k))
        setMemes([...memes, ...parsed_memes])

        // if there are more memes to be loaded and the bottom is visible
        // load more
        if (data.length == 5 && isVisible) {
          setPage(page + 1)
        }
      })
      .catch((error) => {
      });
	}

	if (isMobile) {
	  return(
	  <>
	    <div className="meme-mobile-container">
	     { memes.map((meme, index) => <MemeCard meme={meme} key={index} />)}
	    </div>
	    <div ref={bottomRef}></div>
	  </>
	  )
	}

  return (
    <>
	    { memes.length == 0 ? (
		    <div className="grid-x grid-margin-x align-middle">
		    	Няма Мемета :(
	    	</div>
	    	) : (
	    	  <>
            <div className="grid-x grid-margin-x align-middle" id="kitten-list-grid" >
              { memes.map((meme, index) => <MemeCard meme={meme} key={index} />)}
            </div>
     			</>
	     		)
	    }
	   <div ref={bottomRef}></div>
    </>
  );
};

export default MemeList;
