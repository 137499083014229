import React from "react";
import i18n from "../i18n";
import ShoppingCartBody from "./ShoppingCartBody.jsx";
import PubSub from "pubsub-js";

export default class PaymentCartSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shopping_cart: this.props.shopping_cart,
      quantity_editing_state: false,
    };

    this.updateCart = this.updateCart.bind(this);
    this.finalizeOrder = this.finalizeOrder.bind(this);

    // listens the shopping_cart topic and updates the state with the
    // shopping_cart object that is received
    PubSub.subscribe("shopping_cart", this.updateCart);
  }

  // this is triggered whenever we receive a shopping cart update
  // via the PubSub topic
  updateCart(e, shopping_cart) {
    this.setState({
      shopping_cart: shopping_cart,
    });
  }

  finalizeOrder(e, state) {
    e.preventDefault(e);
    this.setState({ quantity_editing_state: state });
    PubSub.publish("quantity_editing_state", state);

    // publish amount update when only done editing
    if (!state) {
      PubSub.publish("updated_amount", this.state.shopping_cart.total);
    }
  }

  render() {
    return (
      <div>
        <ShoppingCartBody
          shopping_cart={this.state.shopping_cart}
          quantity_editable={true}
        />
      </div>
    );
  }
}
