import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";

const FormBuilderRadioOptions = (props) => {
  const [inputs, setInputs] = useState([
    { id: 0, labelLocal: "", labelEN: "" },
  ]);

  const [labelLocal, setLabelLocal] = useState("");

  // const [labelEN, setLabelEN] = useState('');

  const increaseNumberOfInputs = () => {
    // const id = inputs[inputs.length - 1].id + 1;
    // const newArrOfInputs = inputs.slice();

    // newArrOfInputs[newArrOfInputs.length -1].labelLocal = labelLocal;
    // newArrOfInputs[newArrOfInputs.length -1].labelEN = labelEN;

    // setInputs([...newArrOfInputs, { id, labelLocal: '', labelEN: '' }]);
    // setLabelLocal('');
    // setLabelEN('');

    const id = inputs[inputs.length - 1].id + 1;

    setInputs([...inputs, { id, labelLocal: "", labelEN: "" }]);
  };

  const removeInput = (index) => {
    const listWithRemovedInput = inputs.filter((input) => input.id !== index);
    setInputs(listWithRemovedInput);
  };

  const onChange = (id, value) => {
    let newArrOfInputs = inputs.slice();
    const changedInputIndex = newArrOfInputs
      .map(function (x) {
        return x.id;
      })
      .indexOf(id);

    newArrOfInputs[changedInputIndex].labelLocal = value;

    setInputs(newArrOfInputs);
  };

  useEffect(() => {
    props.setParentRadioOptions(inputs);
  }, [inputs]);

  useEffect(() => {
    if (props.parentRadioOptions.length === 0) {
      setInputs([{ id: 0, labelLocal: "", labelEN: "" }]);
    }
  }, [props.parentRadioOptions]);

  return (
    <>
      {inputs.map((inputObject, index) => {
        return (
          <div>
            <ReactTooltip />
            <div>
              Опция {index + 1} <span data-tip="Полето е задължително">*</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="text"
                defaultValue={inputObject.labelLocal}
                onChange={(e) => onChange(inputObject.id, e.target.value)}
                style={{ marginBottom: "0 !important" }}
              />
              {/* <input type="text" defaultValue={inputObject.labelEN} onChange={(e) => setLabelEN(e.target.value)} /> */}
              {inputs.length > 1 && (
                <div
                  onClick={() => removeInput(inputObject.id)}
                  style={{
                    marginBottom: "24px",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                >
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
            </div>
          </div>
        );
      })}
      <button
        onClick={increaseNumberOfInputs}
        className="button tiny"
        style={{ marginBottom: "50px" }}
      >
        Добави опция
      </button>
    </>
  );
};

export default FormBuilderRadioOptions;
