import React, { useEffect, useState } from "react";
import PubSub from "pubsub-js";

// Responsible for showing the user how many unread messages there are
// we using this component so that fetching the data doesn't cause delays
const UnseenMessagesCount = (props) => {
		const [count, setCount] = useState(0)

	  useEffect(() => {
	  	PubSub.subscribe("unseen_messages_count", (e, count) => setCount(count));
	  }, [])

		return (
	    <div>
	    { count > 0 ? (
		    <div className="callout primary small-padding-mobile radius-large">
					<div className="grid-x grid-margin-x align-middle">
						<div className="cell">						
						</div>
						<div className="cell small-6">
							<h1 className="stat-number text-center no-margin white-font-color">{count}<span className="secondary-font-color">.</span></h1>
						</div>
						<div className="cell small-6">
							<h3 className="white-font-color no-margin" style={{lineHeight: "110%"}}>Нови съобщения</h3>
						</div>
						<div className="cell">
							<p className="no-margin"><a href="/chats" className="button wide tiny secondary no-margin">Към чата</a></p>
						</div>
					</div>
				</div>
				) : null}
	    </div>
  )
}

export default UnseenMessagesCount;