import React, { useState, useEffect } from "react";

const DashboardPostForm = (props) => {
  const { template, slots, photos } = props;

  return (
    <>
      {!template || !photos ? (
        <MissingInformation />
      ) : (
        <NoMissingInformation
          template={template}
          slots={slots}
          photos={photos}
        ></NoMissingInformation>
      )}
    </>
  );
};

export default DashboardPostForm;

const NoMissingInformation = ({ template, slots, photos }) => {
  const [loading, setLoading] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [isValid, setIsValid] = useState(false);

  const handleSlotSelection = (day, slot) => {
    if (slot.available == false) {
      return;
    }

    setSelectedSlot(slotToState(day, slot));
  };

  const slotToState = (day, slot) => {
    return `${day}@${slot.id}`;
  };

  const setSelectedClass = (result) => {
    return result == true ? "selected" : "";
  };

  useEffect(() => {
    setIsValid(
      template.description != "" && photos != [] && selectedSlot != ""
    );
  }, [selectedSlot]);

  const submitPost = () => {
    setLoading(true);
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
    return fetch(`/dashboard/posts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        post: {
          template_identifier: template.identifier,
          slot: selectedSlot,
        },
      }),
    }).then((res) => {
      if (res.status == 200) {
        res.json().then((json) => {
          window.location.href = "/dashboard/posts";
        });
        setLoading(false);
      } else {
        res.json().then((json) => {
          alert("Грешка.");
        });
        setLoading(false);
      }
    });
  };
  return (
    <div>
      <h6>Описание</h6>
      <p>
        {template.description.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </p>
      <h6>Снимки</h6>
      <div className="grid-x grid-margin-x">
        {photos.map((img, index) => (
          <div
            key={index}
            className={`cell post-image-selector small-3 medium-2 relative`}
          >
            <div className="post-image-selector--wrapper relative">
              <img src={`${img.url}`} className={`radius`} />
            </div>
          </div>
        ))}
      </div>
      <h6>График</h6>
      <div className="grid-x">
        {slots.map((slot, index) => (
          <div key={index} className="cell slot-cell">
            {slot.day}
            <br />
            {slot.slots.map((day_slot, index) => (
              <button
                key={index}
                onClick={() => handleSlotSelection(slot.day, day_slot)}
                className={`slot-button ${
                  day_slot.available
                } ${setSelectedClass(
                  selectedSlot == slotToState(slot.day, day_slot)
                )}`}
              >
                {day_slot.name}
              </button>
            ))}
          </div>
        ))}
      </div>
      <br/>
      <button
        className={`button ${!isValid || loading ? "disabled" : ""}`}
        onClick={() => submitPost()}
      >
        Публикувай
      </button>
    </div>
  );
};

const MissingInformation = () => {
  return (
    <div>
      <h6>
        За да публикуваш трябва да имаш одобрена обява в сайта и избран темплейт
        за публикация.{" "}
      </h6>
      <p>Ако имаш коте и не си публикувал, може да го направиш ето тук :</p>
      <p>
        Ако вече си публикувал, но обявата ти не е одорена, ще получиш имейл,
        когато това стане.(това може да отнеме различно време според броя на
        новите обяви)
      </p>
    </div>
  );
};
