import React, { useState, useEffect, useRef } from "react";
import i18n from "../i18n";
import Address from "../shop/Address.jsx";
import EcontOfficeSelector from "../shop/EcontOfficeSelector.jsx";
import { useSpring, animated, config } from "react-spring";
import PubSub from "pubsub-js";

const emptyAddressObject = {
  addressLineFirst: "",
  addressLineSecond: "",
  city: "",
};

const emptyEcontOffice = {
  label: "",
  value: "",
  city: ""
};

const emailError = i18n.t("email_error");
const phoneError = i18n.t("phone_error");
const nameError = "Попълни име и фамилия";
const econtOfficeDeliveryError = "Избери град и офис на Еконт.";
const addressDeliveryError = "Попълни валиден адрес за доставка.";
const privacyError = "Трябва да се запознаеш с политиката за поверителност.";
const termsError = "Трябва да се запознаеш и приемеш общите условия.";
const noDeliveryMethodError = "Избери валиден метод на доставка";

const PaymentAddress = (props) => {
  // *****
  // STATES =======================================
  // *****

  const isInitialMount = useRef(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState(props.user.email || "");
  const [phone, setPhone] = useState("");
  const [shoppingCart, setShoppingCart] = useState(props.shopping_cart);
  const [amount, setAmount] = useState(props.initial_amount);
  const [errors, setErrors] = useState({});
  const [addressDelivery, setAddressDelivery] = useState(
    props.user.address || emptyAddressObject
  );
  const [renderEditButton, setRenderEditButton] = useState(true);

  // econtOfficeDelivery will be populated like this { label: "OFFICE NAME", value: "OFFICE CODE"}
  const [econtOfficeDelivery, setEcontOfficeDelivery] =
    useState(emptyEcontOffice);

  // delivery_type can be either `econt_office` or `econt_delivery` as per shopping_cart.rb
  const [delivery_type, setDeliveryType] = useState(props.delivery_type);
  // *****
  // END STATES =======================================
  // *****

  useEffect(() => {
    isInitialMount.current ? null : verifyPrivacy();
  }, [privacyChecked]);
  useEffect(() => {
    isInitialMount.current ? null : verifyTerms();
  }, [termsChecked]);
  useEffect(() => {
    isInitialMount.current
      ? (isInitialMount.current = false)
      : verifyDelivery();
  }, [econtOfficeDelivery]);

  useEffect(() => {
    PubSub.subscribe("shopping_cart", (e, cart) => setShoppingCart(cart));
    PubSub.subscribe("payment_state", (e, renderStatus) =>
      setRenderEditButton(renderStatus)
    );
  }, []);
  // *****
  // VALIDATIONS =======================================
  // *****

  const handleValidation = (result, errorKey, errorMessage) => {
    result ? clearError(errorKey) : addError({ [errorKey]: errorMessage });
  };

  const addError = (error) => {
    setErrors((prevErrors) => ({ ...prevErrors, ...error }));
  };

  const clearError = (field) => {
    setErrors((prevErrors) => ({ ...prevErrors, ...{ [field]: "" } }));
  };

  const errorClass = (error) => {
    return error ? "invalid" : "";
  };

  const verifyName = () => {
    let re = /^[a-zA-Zа-яА-Я-]{1,40}(\s[a-zA-Zа-яА-Я-]{1,50}){1,2}\s*$/;
    let result = re.test(name);

    handleValidation(result, "name", nameError);

    return !!result;
  };

  const verifyEmail = () => {
    let re = /\S+@\S+\.\S+/;
    let result = re.test(email);

    handleValidation(result, "email", emailError);

    return !!result;
  };

  const verifyPhone = () => {
    let re = /(\+3598|\+3599|08|09)\d{8}/;
    let result = re.test(phone);

    handleValidation(result, "phone", phoneError);

    return !!result;
  };

  const verifyEcontOfficeDelivery = () => {
    return !Object.values(econtOfficeDelivery).includes("");
  };

  const verifyAddressDelivery = () => {
    return !Object.values(addressDelivery).includes("");
  };

  const verifyDelivery = () => {
    if (delivery_type == "econt_office") {
      handleValidation(
        verifyEcontOfficeDelivery(),
        "delivery",
        econtOfficeDeliveryError
      );
      return verifyEcontOfficeDelivery();
    } else if (delivery_type == "econt_delivery") {
      handleValidation(
        verifyAddressDelivery(),
        "delivery",
        addressDeliveryError
      );
      return verifyAddressDelivery();
    } else {
      alert(noDeliveryMethodError);
    }
  };

  const verifyPrivacy = () => {
    handleValidation(!!privacyChecked, "privacy", privacyError);
    return !!privacyChecked;
  };

  const verifyTerms = () => {
    handleValidation(!!termsChecked, "terms", termsError);
    return !!termsChecked;
  };

  const verifyEverything = () => {
    let personalData = !!(verifyPhone() & verifyName() & verifyEmail());
    let deliveryData = verifyDelivery();
    let privacyAndTerms = verifyPrivacy() && verifyTerms();
    console.log("terms", verifyTerms());

    if (!personalData) {
      document
        .getElementById("delivery-user-data")
        .scrollIntoView({ behavior: "smooth" });
    }
    if (personalData && !deliveryData) {
      document
        .getElementById("delivery-type-select")
        .scrollIntoView({ behavior: "smooth" });
    }
    return personalData && deliveryData && privacyAndTerms;
  };
  // *****
  // END VALIDATIONS =======================================
  // *****

  // *****
  // HANDLE DELIVERY STUFF =======================================
  // *****
  const handleAddressChange = (updatedAddress) => {
    setAddressDelivery({ ...addressDelivery, ...updatedAddress });
  };

  const handleEcontOfficeChange = (econtOffice) => {
    console.log(econtOffice, "changed office");
    setEcontOfficeDelivery(econtOffice);
  };

  // determines the CSS classes for the delivery buttons according to the selected type
  // the spacer-border class compensates for the border of the hollow class
  const setDeliverClass = (button_option) => {
    return delivery_type == button_option
      ? "spacer-border"
      : "secondary hollow";
  };

  // makes the request to the backend in order to update the delivery type within the
  // shopping cart and handles the state around it
  const updateDeliveryMethod = (new_delivery_type) => {
    //reset the potentially populated address data to avoid any confusion
    setEcontOfficeDelivery(emptyEcontOffice);
    setAddressDelivery(emptyAddressObject);

    // scrolls the user so that they can see the delivery form
    document
      .getElementById("delivery-type-select")
      .scrollIntoView({ behavior: "smooth" });

    // caches the previously selected type so we can revert to it in case of
    // problems when we try to update the shopping cart. Not the best name...
    let current_delivery_type = delivery_type;

    // updates the state optimistically, before making the actual request to the backend
    // so that there are no delays for the user
    setDeliveryType(new_delivery_type);
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;

    fetch("/shop/cart/set_delivery_type", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        delivery_type: new_delivery_type,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // sends the update shipping_cart object to the shopping_cart topic so that
        // the cart totals are updated according to the selected delivery type
        PubSub.publish("shopping_cart", data);
      })
      .catch((error) => {
        // if the delivery type was not updated successfully, we revert the previously selected one
        // by using the cached value at the beginning
        setDeliveryType(current_delivery_type);
      });
  };
  // *****
  // END HANDLE DELIVERY STUFF =======================================
  // *****

  // *****
  // FINALIZE DELIVERY INFO =======================================
  // *****
  // this is called when the user wants to move to the payment stage of the checkout
  // we need to validate that all information is populated and valid
  const finalizeDelivery = (isEditingState) => {
    if (verifyEverything()) {
      setIsEditing(isEditingState);
      createPaymentIntent();

      //      document
      //        .getElementById('delivery-preview')
      //        .scrollIntoView({ behavior: 'smooth' });

      PubSub.publish("quantity_editing_state", isEditingState);
    }
  };

  const orderAddress = () => {
    if (delivery_type === "econt_office") {
      return econtOfficeDelivery;
    }

    return addressDelivery;
  };

  const parseAmount = (amount) => {
    let re = /[\d\.]+/;
    let [parsedAmount] = amount.match(re);

    return parsedAmount;
  };

  const createPaymentIntent = () => {
    let amount = parseAmount(shoppingCart.total);
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;

    fetch("/shop/payments/create_payment_intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        email: email,
        amount: amount,
        shopping_cart: shoppingCart,
        name: name,
        phone: phone,
        address: orderAddress(),
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        PubSub.publish("updated_delivery_info", {
          paymentIntentId: response.intent_id,
          secret: response.secret,
          amount: amount,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div style={{ display: isDisabled ? "block" : "none" }}>
        <p>{i18n.t("inactive_address_text")}</p>
      </div>

      <div
        className="grid-x"
        style={useSpring({
          height: isEditing && !isDisabled ? "800px" : "0px",
          config: config.slow,
        })}
      >
        <div className="cell">
          <div style={{ display: isEditing && !isDisabled ? "block" : "none" }}>
            <label htmlFor="email">Твоят имейл</label>
            <div className={`field-set ${errorClass(errors.email)}`}>
              <input
                type="email"
                required={true}
                name="email"
                placeholder="user@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => verifyEmail()}
                onKeyUp={() => (errors.email ? verifyEmail() : null)}
              />
              <div className="field-set--error">{errors.email}</div>
            </div>
            <h3>Получател</h3>
            <label htmlFor="name">{i18n.t("name")}</label>
            <div className={`field-set ${errorClass(errors.name)}`}>
              <input
                type="text"
                required={true}
                name="name"
                placeholder="John Doe"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={() => verifyName()}
                onKeyUp={() => (errors.name ? verifyName() : null)}
              />
              <div className="field-set--error">{errors.name}</div>
            </div>
            <label htmlFor="phone">{i18n.t("phone")}</label>
            <div className={`field-set ${errorClass(errors.phone)}`}>
              <input
                type="tel"
                required={true}
                name="phone"
                placeholder="0888 7777 66"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                onBlur={() => verifyPhone()}
                onKeyUp={() => (errors.phone ? verifyPhone() : null)}
              />
              <div className="field-set--error">{errors.phone}</div>
            </div>
            <div id="delivery-type-select">
              <hr />

              <div className="grid-x">
                <div className="cell medium-6">
                  <button
                    className={
                      "button block delivery-button delivery-button-left " +
                      setDeliverClass("econt_office")
                    }
                    onClick={() => updateDeliveryMethod("econt_office")}
                  >
                    <div className="grid-x align-middle">
                      <div className="div large-2 hide-for-medium-only small-2 text-center">
                        <div className="check-placeholder">
                          <i className="fa fa-circle"></i>
                        </div>
                      </div>
                      <div className="div large-10 medium-12 small-10 text-center">
                        <div className="delivery-button--text">
                          Офис на Еконт
                        </div>
                        <div className="delivery-button--price">5.80лв</div>
                      </div>
                    </div>
                  </button>
                </div>
                <div className="cell medium-6">
                  <button
                    className={
                      "button block delivery-button delivery-button-right " +
                      setDeliverClass("econt_delivery")
                    }
                    onClick={() => updateDeliveryMethod("econt_delivery")}
                  >
                    <div className="grid-x align-middle">
                      <div className="div large-2 hide-for-medium-only small-2 text-center">
                        <div className="check-placeholder">
                          <i className="fa fa-circle"></i>
                        </div>
                      </div>
                      <div className="div large-10 medium-12  small-10 text-center">
                        <div className="delivery-button--text">До адрес</div>
                        <div className="delivery-button--price">7.20лв</div>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <br /> {/*lame*/}
            {delivery_type == "econt_office" ? (
              <EcontOfficeSelector
                handleEcontOfficeChange={handleEcontOfficeChange}
                econtOfficeDelivery={econtOfficeDelivery}
              />
            ) : (
              <Address
                handleAddressChange={handleAddressChange}
                address={addressDelivery}
                errors={errors}
                verifyDelivery={verifyDelivery}
              />
            )}
            <div className={`field-set ${errorClass(errors.delivery)}`}>
              <div className="field-set--error" style={{ top: "-24px" }}>
                {errors.delivery}
              </div>
            </div>
            <hr />
            <div id="privacy-and-terms">
              <div className={`field-set ${errorClass(errors.terms)}`}>
                <p className="terms-checks">
                  <input
                    type="checkbox"
                    className="no-margin"
                    name="checkbox"
                    id="checkbox"
                    onChange={() => setTermsChecked(!termsChecked)}
                    checked={termsChecked}
                  />

                  <span>
                    Запознах се и приемам{" "}
                    <a href="https://purrs.bg/Общи Условия_purrs.bg_20211001_clean.pdf" className="global-weight-bold" target="_blank">
                      общите условия
                    </a>
                    .
                  </span>
                </p>
                <div
                  className="field-set--error"
                  style={{ top: "-10px", position: "relative" }}
                >
                  {errors.terms}
                </div>
              </div>

              <div className={`field-set ${errorClass(errors.privacy)}`}>
                <p className="terms-checks">
                  <input
                    type="checkbox"
                    className="no-margin"
                    name="checkbox"
                    id="checkbox"
                    onChange={() => setPrivacyChecked(!privacyChecked)}
                    checked={privacyChecked}
                  />
                  <span>
                    Запознах се с{" "}
                    <a href="https://purrs.bg/Политика за поверителност_purrs.bg_20211001_clean.pdf" className="global-weight-bold" target="_blank">
                      политиката за поверителност
                    </a>
                    .
                  </span>
                </p>
                <div
                  className="field-set--error"
                  style={{ top: "-10px", position: "relative" }}
                >
                  {errors.privacy}
                </div>
              </div>
            </div>
            <div className="cell">
              <a
                href="/"
                className="button wide no-margin"
                onClick={(e) => {
                  e.preventDefault(e);
                  finalizeDelivery(false);
                }}
              >
                Плащане
              </a>
            </div>
          </div>
        </div>
      </div>

      <animated.div id="delivery-preview">
        <div style={{ display: !isEditing && !isDisabled ? "block" : "none" }}>
          <div className="grid-x">
            <div className="cell">{email}</div>
          </div>

          <div className="grid-x">
            <div className="cell">
              <h3>Получател</h3>
            </div>
          </div>
          <div className="grid-x">
            <div className="cell medium-6">{name}</div>

            <div className="cell medium-6">
              {phone} <br />
            </div>
            <div className="cell medium-12">
              <hr className="small-spacing" />

              {delivery_type == "econt_office" && (
                <div>
                  <h3>Доставка до офис на Еконт</h3>
                  {econtOfficeDelivery.label}
                </div>
              )}

              {delivery_type == "econt_delivery" && (
                <div>
                  <h3>Доставка до aдрес с Еконт</h3>
                  {addressDelivery.city}
                  <br />
                  {addressDelivery.addressLineFirst}
                  <br />
                  {addressDelivery.addressLineSecond}
                </div>
              )}
            </div>
            <div className="cell">
              <br />
              {renderEditButton && (
                <button
                  className="button wide hollow no-margin"
                  onClick={(e) => {
                    finalizeDelivery(true);
                  }}
                >
                  {" "}
                  Редактирай
                </button>
              )}
            </div>
          </div>
        </div>
      </animated.div>
    </>
  );
};

export default PaymentAddress;
