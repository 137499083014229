import React, { useState } from "react";
import RenderedForm from "../adoption_forms/RenderedForm.jsx";
import FormBuilderRadioOptions from "./FormBuilderRadioOptions.jsx";
import ReactTooltip from "react-tooltip";

const FormBuilder = (props) => {
  const [type, setType] = useState("");
  const [labelEn, setLabelEn] = useState("");
  const [labelLocal, setLabelLocal] = useState("");
  const [required, setRequired] = useState(false);
  const [error, setError] = useState(false);

  // inputs
  const [inputs, setInputs] = useState(props.inputs || []);

  const [shortTextFormat, setShortTextFormat] = useState("free");

  const [radioOptions, setRadioOptions] = useState([
    { id: 0, labelLocal: "", labelEN: "" },
  ]);

  const submit = () => {
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;

    const arrayOfLabels = radioOptions.map((input) => input.labelLocal);

    if (
      labelLocal.length < 1 ||
      (arrayOfLabels.includes("") && type === "Radio") ||
      type === ""
    ) {
      setError(true);
      return null;
    } else {
      error ? setError(false) : null;
    }

    fetch(`/dashboard/forms/${props.form.identifier}/inputs`, {
      method: "Post",
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        input: {
          type: type,
          format: shortTextFormat ? shortTextFormat : "",
          label_en: labelEn,
          label_local: labelLocal,
          required: required,
          options: radioOptions,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setType("");
        setLabelEn("");
        setLabelLocal("");
        setInputs(data.inputs);
        setRadioOptions([]);
      })
      .catch((error) => {
        console.log("Inputs fetch error", error);
      });
  };

  return (
    <div className="grid-x grid-margin-x">
      <div className="cell medium-6">
        <ReactTooltip />
        <label>
          Въпрос <span data-tip="Полето е задължително">*</span>{" "}
        </label>
        <input
          type="text"
          value={labelLocal}
          onChange={(e) => setLabelLocal(e.target.value)}
        />

        <label>Въпрос (ЕН)</label>
        <input
          type="text"
          value={labelEn}
          onChange={(e) => setLabelEn(e.target.value)}
        />

        <label>
          Тип отговор <span data-tip="Полето е задължително">*</span>
        </label>
        <select value={type} onChange={(e) => setType(e.target.value)}>
          <option>Избери</option>
          <option value="ShortText">Къс текс</option>
          <option value="LongText">Дълъг текст</option>
          <option value="Checkbox">Чекбокс (Да/Не)</option>
          <option value="Radio">Избор от опции</option>
        </select>

        {type === "ShortText" && (
          <div onChange={(e) => setShortTextFormat(e.target.value)}>
            <input
              type="radio"
              id="free"
              value="free"
              name="text_type"
              defaultChecked
            />
            <label
              htmlFor="free"
              style={{ marginRight: "5px", marginLeft: "3px" }}
            >
              Свободен Текс
            </label>
            <input type="radio" id="numbers" value="numbers" name="text_type" />
            <label
              htmlFor="numbers"
              style={{ marginRight: "5px", marginLeft: "3px" }}
            >
              Само числа
            </label>
            <input type="radio" id="date" value="date" name="text_type" />
            <label
              htmlFor="date"
              style={{ marginRight: "5px", marginLeft: "3px" }}
            >
              Дата
            </label>
          </div>
        )}

        {type === "Radio" && (
          <FormBuilderRadioOptions
            parentRadioOptions={radioOptions}
            setParentRadioOptions={setRadioOptions}
          />
        )}

        <div className="terms-checks margin-bottom">
          <input
            type="checkbox"
            className="no-margin"
            onClick={(e) => setRequired(e.target.value)}
          />
          <span>Задължително поле</span>
        </div>
        {error && (
          <div style={{ color: "red" }}>
            Моля, попълнете всички задължителни полета
          </div>
        )}
        <button onClick={() => submit()} className="button">
          Запази
        </button>
      </div>
      <div className="cell medium-6">
        <RenderedForm inputs={inputs} mode={"edit"} />
      </div>
    </div>
  );
};

export default FormBuilder;
