import React, { useState, useEffect } from "react";

const statRow = (props) => {

	const { month } = props
	const [data, setData] = useState("");

  useEffect(() => {
    fetchMonthData();
  },[]);

  const fetchMonthData = () => {
    // we check the cities state before making request to avoid making the same
    // call to the backend as the used types in
    //if (cities.length == 0) {
      let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
      return fetch(`/admin/stats/${month}/data`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json;",
          "X-CSRF-Token": csrf,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // the response from the backend is passed to the state so we can reuse it
          setData(data);
        })
        .catch((error) => {
          console.log("Data fetch error", error);
        });
    //}
  };

	return (
		<div className="grid-x grid-margin-x">
			<div className="cell medium-2">{month}</div>
			<div className="cell medium-2">{data.kittens || 0}</div>
			<div className="cell medium-2">{data.adopted || 0}</div>
			<div className="cell medium-2">{data.posts || 0}</div>
			<div className="cell medium-2">{data.users || 0}</div>
		</div>
	)
}

export default statRow;