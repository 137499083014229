import React, { useState, useEffect } from "react";
import i18n from "../i18n";
import EcontCitySelect from "../shop/EcontCitySelect.jsx";

const styles = {
  container: (provided) => ({
    ...provided,
    padding: 0,
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "white",
    padding: 0,
    borderRadius: 10,
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 10,
  }),
  option: (provided, state) => ({
    ...provided,
    padding: 12,
    fontSize: 15,
  }),
};

const Address = (props) => {
  // we keep the entire address into a single state as an object with multple keys
  // chose to do that as it would be easier to adjust the fields depending on what's
  // needed for the Econt integration later on
  const initialAddress = {
    city: "",
    addressLineFirst: "",
    addressLineSecond: "",
  };
  const [address, setAddress] = useState(initialAddress);

  const updateAddress = (updatedAddress) => {
    setAddress(updatedAddress);
    props.handleAddressChange(updatedAddress);
  };

  return (
    <>
      <h3>Доставка до адрес</h3>
      <EcontCitySelect
        cities={props.cities}
        handleEcontCitySelectChange={(city) =>
          updateAddress({ ...address, city: city.label })
        }
      />

      <label>Улица / Квартал</label>
      <input
        type="text"
        placeholder={"ул. Солунска / ж.к. Младост"}
        onChange={(e) =>
          updateAddress({ ...address, addressLineFirst: e.target.value })
        }
        onKeyUp={() => (props.errors.delivery ? props.verifyDelivery() : null)}
      />

      <label>Номер / Блок, вход, апартамент</label>
      <input
        type="text"
        placeholder={"35 / бл. 123, вх. 5, ап. 1"}
        onChange={(e) =>
          updateAddress({ ...address, addressLineSecond: e.target.value })
        }
        onKeyUp={() => (props.errors.delivery ? props.verifyDelivery() : null)}
      />
    </>
  );
};

export default Address;
