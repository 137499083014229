import React, { useState, useEffect } from "react";
import ShortText from "../adoption_forms/form_elements/ShortText.jsx";
import LongText from "../adoption_forms/form_elements/LongText.jsx";
import Checkbox from "../adoption_forms/form_elements/Checkbox.jsx";
import Radio from "../adoption_forms/form_elements/Radio.jsx";

const RenderedForm = (props) => {
  const [formValues, setFormValues] = useState({});

  const FormElements = {
    ShortText: (input, i) => (
      <ShortText
        input={input}
        key={i}
        formValues={formValues}
        setFormValues={setFormValues}
      />
    ),
    LongText: (input, i) => (
      <LongText
        input={input}
        key={i}
        formValues={formValues}
        setFormValues={setFormValues}
      />
    ),
    Checkbox: (input, i) => (
      <Checkbox
        input={input}
        key={i}
        formValues={formValues}
        setFormValues={setFormValues}
      />
    ),
    Radio: (input, i) => (
      <Radio
        input={input}
        key={i}
        formValues={formValues}
        setFormValues={setFormValues}
      />
    ),
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formValues, "form values");
  };

  useEffect(() => {
    console.log(formValues, "form value changed");
  }, [formValues]);

  return (
    <div className="callout large tertiary radius-large">
      <form onSubmit={handleSubmit}>
        {props.inputs.map((input, i) => {
          return FormElements[input.type](input, i);
        })}
        <button type="submit" className="button">
          Изпрати
        </button>
      </form>
    </div>
  );
};

export default RenderedForm;
