import React, {useState, useEffect} from 'react';
import Cropper from 'react-easy-crop';

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

export const getCroppedImg = async (
  imageSrc,
  crop
) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  /* setting canvas width & height allows us to
  resize from the original image resolution */
  canvas.width = 1200;
  canvas.height = 1500;

  ctx?.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    canvas.width,
    canvas.height
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, 'image/jpeg');
  });
};

export default function ImageCropper(props) {
  const [modalState, setModalState] = useState('');
  const [cropAreaWidth, setCropAreaWidth] = useState('480px')
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    setModalState('visible')
    setCropAreaWidth(calculateHeight)
  },[])

  const calculateHeight = () => {
    const windowWidth = window.innerWidth;

    const smallHeight = `${(window.innerWidth - 40) * 1.25}px`
    
    const mediumHeight = `${(((window.innerWidth / 2) / 2) - 80) * 1.25}px`
    
    return windowWidth < 641 ? smallHeight : mediumHeight
  }

  useEffect(() => {
    setModalState('visible')
  },[props.imageUrl])

  const closeModal = () => {
    props.onCropClose();
    setModalState('');
  }

  const triggerSave = () => {
    props.onSave();
    setModalState('');
  }

  // logic is a bit twisted - because of the way the library works,
  // if a photo has a vertical orientation we need to use horizontal-cover
  // and vice versa 
  const cover = (orientation) => {
    return orientation == 'vertical' ? 'horizontal-cover' : 'vertical-cover'
  }

  return (
    <div className={`crop-modal ${modalState}`} id="image-crop" data-reveal>
      <div className="grid-x grid-margin-x">
        <div className="cell medium-6">
        <p>Намести снимката така, че котето да е в центъра на прозореца с приплъзване и/или като я увеличиш/смалиш.</p>
        </div>
        <div className="cell medium-6">
          <div id="outer-crop-container">
            <Cropper
               image={props.imageUrl}
               crop={crop}
               zoom={zoom}
               cropShape="rect"
               aspect={4/5}
               objectFit={cover(props.orientation)}
               onCropChange={setCrop}
               onCropComplete={props.onCropComplete}
               onZoomChange={setZoom}
                style={{
                  containerStyle: {
                      height: cropAreaWidth,
                      position: "relative",
                      margin: "auto"
                    },
                }}
             />
           </div>
           <br/>
          <button
            className="button no-margin"
            onClick={triggerSave}
          >
            Готово
          </button>
        </div>
     </div>
     <button className="close-button" onClick={closeModal} aria-label="Close modal" type="button">
       <span aria-hidden="true">&times;</span>
     </button>
    </div>
    )
}