import React, { useEffect } from "react";

const Checkbox = (props) => {
  const { input, formValues, setFormValues } = props;

  useEffect(() => {
    if (formValues[`${input.label_local} (${input.id})`] === undefined) {
      setFormValues({
        ...formValues,
        [`${input.label_local} (${input.id})`]: false,
      });
    }
  }, [formValues]);

  return (
    <div className="terms-checks margin-bottom">
      <input
        type="checkbox"
        className="no-margin"
        required={input.required}
        name={input.label_local}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            [`${input.label_local} (${input.id})`]: e.target.checked,
          })
        }
      />
      <span>
        {input.label_local}
        {input.required ? "*" : ""}
      </span>
    </div>
  );
};

export default Checkbox;
