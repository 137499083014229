import React, { useState, useEffect } from "react";
import ImagePreview from "../ImagePreviewWithCrop.jsx";

const EditUser = (props) => {

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState(props.user.name)
  const [email, setEmail] = useState(props.user.email)
  const [phone, setPhone] = useState(props.user.phone)
  const [username, setUsername] = useState(props.user.username)
  const [currentAvatar, setCurrentAvatar] = useState(props.user.adjusted_avatar);
  const [avatar, setAvatar] = useState("");
  const [avatarPreview, setAvatarPreview] = useState("");
  const [customAvatar, setCustomAvatar] = useState(props.user.custom_avatar);
  // holds a list of errors
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState("");

  // this creates an avatar preview upon upload. concept is copied from there:
  // https://stackoverflow.com/questions/38049966/get-image-preview-before-uploading-in-react/57781164#comment94991328_54060913
  useEffect(() => {
    if (!avatar) {
      setAvatarPreview(undefined)
      return
    }
    // create the preview
    const objectUrl = URL.createObjectURL(avatar)
    setAvatarPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [avatar])

  const validUsername = username => {
    return /^[a-zA-Z0-9]+$/.test(username);
  }

  // submits the user info to the be
  const submit = () => {
    setSuccess('')
    
    if (name == "") {
      setErrors([... new Set([...errors, 'Име и фамилия е празно'])])
    }
    if (phone == "") {
      setErrors([... new Set([...errors, 'Телефон е празно'])])
    }
    if (phone == "" || name == "") {
      return; 
    }

      const body_with_avatar = {
        user: {
          email: email,
          name: name,
          phone: phone,
          username: username,
          avatar: avatar,
        }
      }

      const body_without_avatar = {
        user: {
          email: email,
          name: name,
          phone: phone,
          username: username,
        }
      }

      const adjusted_body = avatar == "" ? body_without_avatar : body_with_avatar

      let formData = new FormData();

      for(const dataKey in adjusted_body) {
        if(dataKey === 'user') {
          for (const previewKey in adjusted_body[dataKey]) {
            formData.append(`user[${previewKey}]`, adjusted_body[dataKey][previewKey]);
          }
        }
        else {
          formData.append(dataKey, adjusted_body[dataKey]);
        }
      }

      setLoading(true);
      let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
      return fetch(`/dashboard/profile/me`, {
        method: "PATCH",
        headers: {
          "X-CSRF-Token": csrf,
        },
        body: formData
      }).then((res) => {
        if (res.status == 200) {
          console.log(res)
          res.json().then(json => {

            props.reload_on_success ? location.reload() : ""
            console.log(json);
            setErrors([]);
            setSuccess('success')
          });
          setLoading(false);
      } else {
        res.json().then(json => {
          console.log(json);
          setErrors(json);
        });
        setLoading(false);
      }
    })
  };

  const clearSavedAvatar = () => {
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
    return fetch(`/dashboard/profile/me/clear_avatar`, {
      method: "GET",
      headers: {
        "X-CSRF-Token": csrf,
      },
    }).then((res) => {
      if (res.status == 200) {
        res.json().then(json => {
          console.log(json)
          setCurrentAvatar(json.adjusted_avatar);
          setCustomAvatar(false);
        });
    } else {
      res.json().then(json => {
        console.log(json);
      });
    }
    })    
  }

  const clearPendingAvatar = () => {
    setAvatar("")
  }

  const clearAvatar = () => {
    if (avatar != "") {
      clearPendingAvatar();
    }
    if (avatar == "" && customAvatar) {
      clearSavedAvatar();
    }
  }

  console.log(validUsername(username))
  console.log(avatar)
  //////////////////////////
  /// TEMPLATE
  //////////////////////////

	return (
    <div>
      <section className="relative">
    		<div className="grid-x grid-margin-x">
          <div className="cell">
            <h4>Лични данни</h4>
          </div>
          <div className="cell medium-6">
            <label>Име и фамилия</label>
            <input type="email" defaultValue={name} onChange={(e) => setName(e.target.value)} />

            <label>Телефон</label>
            <input type="text" defaultValue={phone} onChange={(e) => setPhone(e.target.value)} />
          </div>
          <div className="cell medium-6">

          </div>
    		</div>
      </section>

      <hr/>

      <section className="relative">
        <div className="grid-x grid-margin-x">
          <div className="cell">
            <h4>Допълнителна информация</h4>
          </div>
          <div className="cell medium-6">
            <label>Потребителско име</label>
            <div className={`validated-field ${validUsername(username)}`}>
              <i className="fa-solid fa-check"></i>
              <i className="fa-solid fa-xmark"></i>
              <input type="text" className="no-margin" defaultValue={username} onChange={(e) => setUsername(e.target.value)} />
              <div className={`field-explainer`}>Само букви и цифри, без интервали и специални символи.</div>
            </div>

            <label>Профилна снимка</label>
            <div className="callout dashed">
              <div className="grid-x align-middle">
                <div className="cell small-7">
                  <label htmlFor="user_avatar" className="button no-margin small secondary"> <i className="fa fa-cloud-upload"></i> Качване</label>
                  <input id="user_avatar" type="file" accept="image/png,image/jpeg" multiple={false} onChange={(e) => setAvatar(e.target.files[0])} className="show-for-sr" />
                  <p>
                  <small>(Използвай квадратна снимка с минимална резолюция 600х600.)</small></p>
                </div>
                <div className="cell small-5">
                  <div className={`avatar no${props.user.default_avatar_id}`} style={{backgroundImage: `url(${avatarPreview || currentAvatar})`}} >
                    { avatar != "" || customAvatar ? (
                      <button onClick={(e) => clearAvatar()}><i className="fa-solid fa-circle-xmark"></i></button>
                    ) : ("")}
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="cell medium-6">

          </div>
        </div>
        
      </section>      

      <hr/>

      <section className="relative">
        <div>
          { errors.length > 0 && 
            <div className="callout small-padding-mobile mobile-small-font error round">
              <h6>Грешка</h6>
              <ul>
              {errors.map((err) => (
                <li key={Math.random()}>{err}</li>
              ))}
              </ul>
            </div>
          }
          { success != "" && 
            <div className="callout small-padding-mobile mobile-small-font success round">
              <h6>Успешен запис</h6>
              <p>Промените са записани успешно.</p>
            </div>
          }
        </div>
        <div>
          <button disabled={loading} className={"button wide large"} onClick={(e) => submit(e)}>Запази</button>
        </div>
      </section>
    </div>
	)
}

export default EditUser;