import React from "react";
import i18n from "../i18n";

export default class UserDetails extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.props.handleUserChange({ [name]: value });
  }

  render() {
    let { name, email, phone } = this.props.user;

    return (
      <div>
        <h4>{i18n.t("user_details_title")}</h4>
        <div>
          <label>{i18n.t("name")}</label>
          {this.props.errors.name}
          <input
            type="text"
            name="name"
            placeholder=""
            value={name}
            onChange={this.handleChange}
          />
          <label>{i18n.t("email")}</label>
          {this.props.errors.email}
          <input
            type="text"
            name="email"
            placeholder=""
            value={email}
            onChange={this.handleChange}
          />
          <label>{i18n.t("phone")}</label>
          {this.props.errors.phone}
          <input
            type="text"
            name="phone"
            placeholder=""
            value={phone}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}
