import React, { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";

const SwiperCarousel = (props) => {
	const [slides, setSlides] = useState(props.slides);

  console.log(slides)

  return (
    <>
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
      pagination={true} modules={[Pagination]} 
    >
    {slides.map((slide) => (
      <SwiperSlide key={Math.random()}>
      <img src={slide.url} />
      <a href={`/fun/new/${props.kitten_identifier}/${slide.id}`} className="swiper-gallery-button button secondary tiny">
        <i className="fa-solid fa-plus"></i>&nbsp;
        <i className="fa-solid fa-face-laugh-beam"></i>
      </a>
      </SwiperSlide>      
    ))}
    </Swiper>
    </>
  );
};

export default SwiperCarousel;