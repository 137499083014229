import React from "react";
import i18n from "../i18n";

export default class Preview extends React.Component {
  render() {
    return (
      <div className="grid-x">
        <div className="cell medium-12">
          <table>
            <tbody>
              <tr>
                <th>{i18n.t("cats_count_title")}</th>
                <td>{this.props.state.catsCount}</td>
              </tr>
              <tr>
                <th>{i18n.t("period")}</th>
                <td>
                  {this.props.state.startDate} - {this.props.state.endDate}
                </td>
              </tr>
              <tr>
                <th>{i18n.t("key_arrangements")}</th>
                <td>
                  <div>
                    {i18n.t("pick_up")}: {this.props.state.pickUpDate}{" "}
                    {this.props.state.pickUpHour}{" "}
                  </div>
                  <div>
                    {i18n.t("drop_off")}: {this.props.state.dropOffDate}{" "}
                    {this.props.state.dropOffHour}
                  </div>
                </td>
              </tr>
              <tr>
                <th>{i18n.t("sitter")}</th>
                <td>{i18n.t(this.props.state.sitter)}</td>
              </tr>
              <tr>
                <th>{i18n.t("address")}</th>
                <td>
                  <div> {this.props.state.address.street} </div>
                  <div>
                    Entr. {this.props.state.address.entrance} Ap.{" "}
                    {this.props.state.address.apartment}
                  </div>
                </td>
              </tr>
              <tr>
                <th>{i18n.t("personal_info")}</th>
                <td>
                  <div> {this.props.state.user.name} </div>
                  <div>
                    {i18n.t("email")}: {this.props.state.user.email}{" "}
                  </div>
                  <div>
                    {i18n.t("phone")}: {this.props.state.user.phone}
                  </div>
                </td>
              </tr>
              <tr>
                <th>{i18n.t("visits")}</th>
                <td>
                  <div>
                    {" "}
                    {i18n.t("count")}: {this.props.state.visitsCount}{" "}
                  </div>
                  <div>
                    {" "}
                    {i18n.t("dates")}: {this.props.state.visitDates.join(", ")}{" "}
                  </div>
                </td>
              </tr>
              <tr>
                <th>{i18n.t("amount")}</th>
                <td>{this.props.state.amount} BGN </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="cell medium-8"></div>
        <div className="cell medium-4">
          <a className="button" onClick={() => this.props.createBooking()}>
            {i18n.t("submit")}
          </a>
        </div>
      </div>
    );
  }
}
