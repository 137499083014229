import React, {useState, useEffect} from "react";
import CardDetails from "./CardDetails.jsx";
import i18n from "../i18n";

export function DonationForm(props) {
  const [step, setStep] = useState(1);
  const [error, setError] = useState({});
  const [secret, setSecret] = useState(null);
  const [name, setName] = useState(props.name || "");
  const [email, setEmail] = useState(props.email || "");
  const [amount, setAmount] = useState(0);
  const [paymentIntentId, setPaymentIntentId] = useState("")

  const emailError = i18n.t("email_error");
  const amountError = i18n.t("donation_amount_error");

  const goBack = () => {
    setStep(1);
  }

  const goToPaymentStep = () => {
    if (isFormValid()) {
      createPaymentIntent();
    }
  }

  const createPaymentIntent = () => {
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;

    fetch(`/orgs/${props.organization_url}/donations/create_payment_intent`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        email: email,
        amount: amount,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setSecret(response.client_secret);
        setStep(2);
        setPaymentIntentId(response.payment_intent_id);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const createDonation = () => {
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;

    let donationObject = {
      email: email,
      amount: amount,
      name: name,
      payment_intent_id: paymentIntentId,
      donation_campaign_id: props.campaign_id || null,
    };

    fetch(`/orgs/${props.organization_url}/donations`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({ donation: donationObject }),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Form validation
  const isFormValid = () => {
    let fields = ["email", "amount"];
    let [isEmailValid, isAmountValid] = fields.map((field) =>
      verifyFormField(field)
    );

    return !!(isEmailValid && isAmountValid);
  }

  const verifyFormField = (field) => {
    if (field == "email") return verifyEmail();
    else return verifyAmount();
  }

  const verifyEmail = () => {
    let re = /\S+@\S+\.\S+/;
    let result = re.test(email);

    result
      ? clearError("email")
      : addError({ email: emailError });

    return !!result;
  }

  const verifyAmount = () => {
    let re = /\d{1,3}(?:[.,]\d{3})*(?:[.,]\d{2})?/;
    let result = re.test(amount) && amount >= 1;

    result
      ? clearError("amount")
      : addError({ amount: amountError });

    return !!result;
  }

  const addError = (newError) => {
    setError( { ...error, ...newError } );
  }

  const clearError = (field) => {
    setError({ ...error, ...{ [field]: "" } } );
  }

  return(
    <div className="relative">
      {step == 1 && (
        <form
          className="new_donation"
          onSubmit={(e) => {
            e.preventDefault();
            goToPaymentStep();
          }}
        >
          <label htmlFor="email">{i18n.t("email")}</label>
          {error.email}
          <input
            type="email"
            required={true}
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={(e) => verifyFormField("email")}
            min={1}
          />

          <label htmlFor="amount">{i18n.t("amount")}</label>
          <input
            required={true}
            type="text"
            name="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            onBlur={(e) => verifyFormField("amount")}
            className="no-margin"
          />
          {error.amount}
          <div id="quick-amount-wrapper">
            <span onClick={() => setAmount(5) }>5лв</span>
            <span onClick={() => setAmount(10) }>10лв</span>
            <span onClick={() => setAmount(25) }>25лв</span>
            <span onClick={() => setAmount(50) }>50лв</span>
            <span onClick={() => setAmount(100) }>100лв</span>
          </div>

          <div className="payment-button-wrapper">
            <button className="button wide no-margin" type="submit">
              {i18n.t("next")}
            </button>
          </div>
        </form>
      )}

      {step == 2 && secret && (
        <span>
          <a className="card-go-back-link" onClick={() => goBack()}>
            {i18n.t("back")}
          </a>

          <CardDetails
            name={name}
            amount={amount}
            secret={secret}
            stripe_pk={props.stripe_pk}
            onSuccess={createDonation}
            handleChange={(e) => setName(e.target.value)}
            stripe_acc={props.stripe_acc}
            successText={"Дарение бе извършено успешно!"}
          />
        </span>
      )}
    </div>
  )
}

export default DonationForm;