import React from "react";
import i18n from "../i18n";

export default class NumberOfCats extends React.Component {
  radioInput(value) {
    return (
      <input
        type="radio"
        id={`cat-${value}`}
        value={value}
        className="large-radio"
        name="catsCount"
        defaultChecked={this.props.catsCount == value}
        onChange={this.props.handleChange}
      />
    );
  }

  render() {
    return (
      <span>
        <h4>{i18n.t("cats_count_title")}</h4>

        {this.props.errors.catsCount}

        <div className="radio-buttons-set">
          <div className="collection-radio-buttons">
            {this.radioInput(1)}
            <label htmlFor="cat-1">1</label>
          </div>
          <div className="collection-radio-buttons">
            {this.radioInput(2)}
            <label htmlFor="cat-2">2</label>
          </div>
          <div className="collection-radio-buttons">
            {this.radioInput(3)}
            <label htmlFor="cat-3">3</label>
          </div>
        </div>
      </span>
    );
  }
}
