import React, { useState } from "react";

const AdminDescriptionFixForm = (props) => {
    const { id } = props;
    const [fixes, setFixes] = useState([]);

    const handleChange = (fix) => {
        if (fixes.includes(fix)) {
            // If the fix is already in the list, remove it
            setFixes(fixes.filter(f => f !== fix));
        } else {
            // If the fix is not in the list, add it
            setFixes([...fixes, fix]);
        }
    };

    console.log(fixes)
    return (
        <div className="checkbox-container" id={`checkbox-container-${id}`}>
            <label className="inline">
                <input
                    type="checkbox"
                    checked={fixes.includes("punctuation")}
                    onChange={() => handleChange("punctuation")}
                />
                Punctuation
            </label>
            <label  className="inline">
                <input
                    type="checkbox"
                    checked={fixes.includes("voice")}
                    onChange={() => handleChange("voice")}
                />
                Voice
            </label>
            <label className="inline">
                <input
                    type="checkbox"
                    checked={fixes.includes("detail")}
                    onChange={() => handleChange("detail")}
                />
                Detail
            </label>
            <a href={`/admin/kittens/${id}/fix_description?fixes=${fixes.join(",")}`} className="button small">Fix</a>
        </div>
    );
};

export default AdminDescriptionFixForm;
