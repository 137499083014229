import React from "react";

export default class StepButton extends React.Component {
  render() {
    const { name, goToStep } = this.props;
    let icon =
      name == "previous" ? (
        <i className="fa fa-chevron-left"></i>
      ) : (
        <i className="fa fa-chevron-right"></i>
      );
    return (
      <span>
        <a
          className="button small"
          onClick={() => this.props.changeStep(goToStep)}
        >
          {icon}
        </a>
      </span>
    );
  }
}
