import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: {
      escapeValue: false
    },
    lng: "bg" || "en",
    fallbackLng: 'bg',
    resources: {
      en: {
        translation: {
          //Donations
          name: 'Name',
          email: 'Email',
          amount: 'Amount',
          custom_amount: 'Custom Amount',
          make_donation: 'Make a donation',
          next: 'Next',
          back: 'Go Back',
          name_error: "Enter a valid name. ",
          email_error: "Email should be in the correct format. ",
          phone_error: "Phone number should be valid.",
          donation_amount_error: "Amount should be a positive number. ",
          //CardDetails
          cardholder_name: "Cardholder's name",
          credit_or_debit_card: 'Credit or debit card',
          donate_button: "Donate {{amount}} BGN",
          pay_button: "Pay{{amount}} BGN",
          invalid_expiry_year_past: "Your card's expiration date is in the past.",
          incomplete_number: "Your card number is incomplete.",
          invalid_number: "Your card number is invalid.",
          incomplete_zip: "Your postal code is incomplete.",
          incomplete_cvc: "Your card's security code is incomplete.",
          card_declined: "Your card was declined.",
          payment_intent_authentication_failure: "We are unable to authenticate your payment method. Please choose a different payment method and try again.",
          incomplete_expiry: "Your card's expiry date is incomplete.",
          card_number_in_name_field: "Please make sure that the name field doesn't contain a card number.",
          // Cat Sitting
          option_missing: "Please select an option.",
          start_date_missing: "Enter start date in such format dd/mm/yyyy.",
          end_date_missing: "Enter end date in such format dd/mm/yyyy.",
          period_error: "Start date should be in the future and before the end date.",
          address_error: "Enter a street.",
          phone_error: "Enter a valid phone.",
          cats_count_title: "How many kittens?",
          booking_period_title: "When is your trip?",
          start_date: "Start date",
          end_date: "End date",
          choose_sitter_title: "Choose a sitter",
          biser: "Biser",
          toni: "Tony",
          booking_address_title: "Where do the kittens live?",
          street: "street",
          entrance: "entrance",
          apartment: "unit",
          unit: "unit",
          street_number: "street_number",
          city: "city",
          user_details_title: "Tell us more about you",
          phone: "Phone",
          submit: "Submit",
          period: "Period",
          sitter: "Sitter",
          address: "Address",
          personal_info: "Personal Information",
          booking_key_pickup_title: "When should we pick up and return the key?",
          pickup_date: "Pick up date",
          pickup_hour: "Pick up hour",
          dropoff_date: "Drop off date",
          dropoff_hour: "Drop off hour",
          pick_up_start_date_conflict: "The 'Pick up' date has to be before the beginning of your trip.",
          drop_off_end_date_conflict: "The 'Drop off' date has to be after your trip has ended.",
          pick_up_hour_error: "The 'Pick up' time has to be in HH:MM format. ",
          drop_off_hour_error: "The 'Drop off' time has to be in HH:MM format. ",
          key_arrangements: "Key Arrangements",
          pick_up: "Pick up",
          drop_off: "Drop off",
          amount: "Amount",
          visits: "Visits",
          count: "Count",
          dates: "Dates",
          pay_button: "Pay {{amount}} BGN",
          // Shop fields
          inactive_address_text: "Finalize order before continuing to delivery details",
          inactive_payment_form_text: "Finalize previous steps before continuing to payment details.",
        },
      },
      bg: {
        translation: {
          //Donations
          name: "Име и фамилия",
          email: 'Имейл',
          amount: 'Сума',
          custom_amount: 'Друга Сума',
          make_donation: 'Направи дарение',
          next: 'Продължи',
          back: 'Назад',
          name_error: "Въведете валидно име. ",
          email_error: "Имейлът трябва да е в коректен формат. ",
          phone_error: "Телефонът трябва да е валиден.",
          donation_amount_error: "Сумата трябва да е положително число. ",
          //CardDetails
          cardholder_name: 'Име на картодържателя',
          credit_or_debit_card: 'Кредитна или дебитна карта',
          donate_button: "Дари {{amount}} лв",
          pay_button: "Плати ({{amount}}лв)",
          incomplete_cvc: 'Кодът Ви за сигурност е недовършен.',
          incomplete_zip: 'Пощенският Ви код е недовършен.',
          incomplete_number: 'Номерът на Вашата карта е недовършен.',
          invalid_number: 'Номерът на Вашата карта е невалиден.',
          payment_intent_authentication_failure: "Не можем да автентифицираме Вашият начин на плащане. Моля изберете друг и опитайте отново.",
          invalid_expiry_year_past: "Картата Ви е с изтекъл срок.",
          card_declined: "Картата Ви беше отхвърлена.",
          incomplete_expiry: "Срокът на картата Ви е недовършен.",
          card_number_in_name_field: "Моля, проверете дали не сте въвели номера на картата в полето за име.",
          // Cat Sitting
          option_missing: "Моля, изберете опция.",
          start_date_missing: "Моля, въведете начална дата във формат dd/mm/yyyy.",
          end_date_missing: "Моля, въведете крайна дата във формат dd/mm/yyyy.",
          period_error: "Началната дата трябва да бъде в бъдещето и преди крайната дата.",
          address_error: "Моля, въведете улица и номер.",
          phone_error: "Моля, въведете валиден телефонен номер.",
          cats_count_title: "Колко са котетата?",
          booking_period_title: "Кога е пътуването?",
          start_date: "Начална дата",
          end_date: "Крайна дата",
          choose_sitter_title: "Избери коткогледач",
          biser: "Бисер",
          toni: "Тони",
          booking_address_title: "Къде живеят котетата?",
          street: "улица",
          entrance: "вход",
          apartment: "апартамент",
          unit: "вход",
          street_number: "номер на улица",
          city: "град",
          user_details_title: "Малко повече информация за теб",
          phone: "Телефон",
          submit: "Запази",
          period: "Период",
          sitter: "Коткогледач",
          address: "Адрес",
          personal_info: "Лични Данни",
          booking_key_pickup_title: "Кога да вземем ключа?",
          pickup_date: "Дата за взимане",
          pickup_hour: "Час за взимане",
          dropoff_date: "Дата за връщане",
          dropoff_hour: "Час за връщане",
          pick_up_start_date_conflict: "Датата за взимане на ключа трябва да е преди началната дата на пътуването. ",
          drop_off_end_date_conflict: "Датата за връщане на ключа трябва да е след крайната дата на пътуването. ",
          pick_up_hour_error: "Часът за взимане трябва да бъде въведен в формат HH:MM. ",
          drop_off_hour_error: "Часът за връщане трябва да бъде въведен в формат HH:MM. ",
          key_arrangements: "Взимане/връщане на ключ",
          pick_up: "Взимане",
          drop_off: "Връщане",
          amount: "Сума",
          visits: "Посещения",
          count: "Брой",
          dates: "Дати",
          pay_button: "Плати {{amount}} лв",
          // Shop fields
          inactive_address_text: "Завърши поръчката за да преминеш към данните за доставка.",
          inactive_payment_form_text: "Завърши предните стъпки преди да преминеш към плащане.",
        },
      },
    },
    whitelist: ["en", "bg"],
    nonExplicitWhitelist: true,
    load: 'languageOnly'
  })

export default i18next;
