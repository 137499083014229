import React, { useState, useEffect, useRef } from "react";
import i18n from "../i18n";

const KittenColorPicker = (props) => {

	const [selection, setSelection] = useState(props.colors || []);
	
	const handleOptionSelection = (selectedOption) => {
		if (selection.includes(selectedOption)) {
			let value = selection.filter(c => c != selectedOption)
			setSelection(value)
			props.setFilteredColors(value)
		} else {
			let value = selection.concat(selectedOption)
			setSelection(value)	
			props.setFilteredColors(value)
		}
	}

	const handleOptionElemState = (option) => {
		return selection.includes(option) ? "selected" : ""
	}
  return (
  	<>
	    <label>Цвят</label>
	    <div className="kitten-color-picker">
	    	<a className={`kitten-color-picker--option black ${handleOptionElemState('black')}`} 
	    		onClick={() => handleOptionSelection("black")}><i className="fa fa-check"></i></a>

	    	<a className={`kitten-color-picker--option stripes ${handleOptionElemState('stripes')}`} 
	    		onClick={() => handleOptionSelection("stripes")}><i className="fa fa-check"></i></a>

	    	<a className={`kitten-color-picker--option gray ${handleOptionElemState('gray')}`} 
	    		onClick={() => handleOptionSelection("gray")}><i className="fa fa-check"></i></a>

	    	<a className={`kitten-color-picker--option orange ${handleOptionElemState('orange')}`} 
	    		onClick={() => handleOptionSelection("orange")}><i className="fa fa-check"></i></a>

	    	<a className={`kitten-color-picker--option white ${handleOptionElemState('white')}`} 
	    		onClick={() => handleOptionSelection("white")}><i className="fa fa-check"></i></a>
		  </div>
		  <input type="hidden" value={selection} name="kitten[colors]"/>
	  </>
  );
};

export default KittenColorPicker;
