import React from "react";
import QuantityUpdater from "./QuantityUpdater.jsx";

export default class ShoppingCartLineItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      item: this.props.item,
      quantity_editable: this.props.quantity_editable,
    };

    this.updateEditingState = this.updateEditingState.bind(this);

    PubSub.subscribe("quantity_editing_state", this.updateEditingState);
  }

  updateEditingState(e, state) {
    this.setState({
      quantity_editable: state,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.item !== prevProps.item) {
      this.setState({
        item: this.props.item,
      });
    }
  }

  render() {
    return (
      <div className="grid-x align-middle">
        <div className="cell large-2 medium-4 small-5 shopping-cart--line-item--image">
          <img src={this.state.item.image.url} className="radius" />
        </div>
        <div className="cell medium-4 hide-for-medium-only hide-for-small-only text-center">
          {this.state.item.name}
        </div>
        <div className="cell large-2 medium-2 small-4 text-center">
          {this.state.quantity_editable == true ? (
            <QuantityUpdater
              sign={"minus"}
              variant_id={this.state.item.variant_id}
            />
          ) : (
            ""
          )}
          <span className="shopping-cart--line-teim--quantity">
            {this.state.item.quantity}
          </span>
          {this.state.quantity_editable == true ? (
            <QuantityUpdater
              sign={"plus"}
              variant_id={this.state.item.variant_id}
            />
          ) : (
            ""
          )}
        </div>
        <div className="cell large-2 medium-3 small-2 hide-for-small-only text-center">
          {this.state.item.unit_price}
        </div>
        <div className="cell large-2 medium-3 small-3 text-center">
          {this.state.item.total}
        </div>
      </div>
    );
  }
}
