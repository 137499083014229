import React from "react";
import i18n from "../i18n";

export default class SitterSelect extends React.Component {
  radioInput(value) {
    return (
      <input
        type="radio"
        id={`sitter-${value}`}
        value={value}
        className="large-radio"
        name="sitter"
        defaultChecked={this.props.sitter == value}
        onChange={this.props.handleChange}
      />
    );
  }

  render() {
    return (
      <div>
        <h4>{i18n.t("choose_sitter_title")}</h4>
        {this.props.errors.sitter}

        <div className="grid-x">
          <div className="cell medium-6">
            <img
              src={this.props.maleAvatar}
              alt="biser male-avatar"
              width="150"
              height="150"
            />
          </div>
          <div className="cell medium-6">
            <img
              src={this.props.femaleAvatar}
              alt="toni female-avatar"
              width="150"
              height="150"
            />
          </div>
          <div className="cell medium-6">
            {this.radioInput("biser")}
            <label htmlFor="sitter-biser">{i18n.t("biser")}</label>
          </div>
          <div className="cell medium-6">
            {this.radioInput("toni")}
            <label htmlFor="sitter-toni">{i18n.t("toni")}</label>
          </div>
        </div>
      </div>
    );
  }
}
