import React, { useState, useEffect, useRef } from "react";
import EcontCitySelect from "../shop/EcontCitySelect.jsx";
import KittenColorPicker from "../kittens/KittenColorPicker.jsx";
import ImagePreview from "../ImagePreviewWithCrop.jsx";
import i18n from "../i18n";

const KittenForm = (props) => {
  
  const DESCRIPTION_MIX_WORD_COUNT = 80;

  const { kitten } = props;
  // city.value is the city ID
  const [city, setCity] = useState(kitten.parsed_city || {});
  const [error, setError] = useState([]);
  const [approxDob, setApproxDob] = useState(kitten.approx_dob);
  const [gender, setGender] = useState(kitten.gender);
  const [vaccinated, setVaccinated] = useState(kitten.vaccinated);
  const [neutered, setNeutered] = useState(kitten.neutered);

  const [felid_3_negative, setFelid_3_negative] = useState(
    kitten.felid_3_negative
  );
  const [fiv_felv_negative, setFiv_felv_negative] = useState(
    kitten.fiv_felv_negative
  );
  const [socialization_level, setSocialization_level] = useState(
    kitten.socialization_level
  );

  const [requires_inside_housing, setRequires_inside_housing] = useState(
    kitten.requires_inside_housing
  );
  const [requires_no_kids, setRequires_no_kids] = useState(
    kitten.requires_no_kids
  );
  const [requires_no_dogs, setRequires_no_dogs] = useState(
    kitten.requires_no_dogs
  );
  const [requires_no_other_cats, setRequires_no_other_cats] = useState(
    kitten.requires_no_other_cats
  );

  const [description, setDescription] = useState(kitten.description);
  const [wordCount, setWordCount] = useState(0);
  const [colors, setColors] = useState(kitten.colors);
  const [dewormed, setDewormed] = useState(kitten.dewormed);
  const [existingImages, setExistingImages] = useState(
    kitten.parsed_images || []
  );
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  console.log(kitten);

  useEffect(() => {
    // Update the word count whenever the description changes
    if (description) {
      setWordCount(countWords(description));
    } else {
      setWordCount(0);
    }
  }, [description]);

  const countWords = (text) => {
    // Split by spaces, filter out empty strings
    return text
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0).length;
  };

  const submit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
    let endpoint = "/dashboard/kittens";
    let method = "POST";

    if (kitten.identifier) {
      endpoint = `/dashboard/kittens/${kitten.identifier}`;
      method = "PATCH";
    }

    fetch(endpoint, {
      method: method,
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        user_identifier: props.user_identifier,
        kitten: {
          approx_dob: approxDob,
          city_id: city.value,
          gender,
          vaccinated,
          dewormed,
          description,
          colors,
          images,
          neutered,
          requires_inside_housing,
          requires_no_kids,
          requires_no_dogs,
          requires_no_other_cats,
          felid_3_negative,
          fiv_felv_negative,
          socialization_level,
        },
      }),
    }).then((res) => {
      if (res.status == 200) {
        console.log(res);
        res.json().then((json) => {
          setError([]);
          window.location.href = `/dashboard/kittens/${json.identifier}${
            kitten.identifier ? "" : "?initial=true"
          }`;
        });
      } else {
        res.json().then((json) => {
          console.log(json);
          setError(json);
        });
        setIsLoading(false);
      }
    });
  };

  const addNewBlob = (blobSignedId) => {
    setImages([...images, blobSignedId]);
  };

  const totalImagesCount = images.length + existingImages.length;

  return (
    <div id="kitten-form">
      <section className="relative">
        <div className="grid-x grid-margin-x">
          <div className="cell">
            <h4>Местоположение</h4>
          </div>
          <div className="cell medium-6">
            <EcontCitySelect
              handleEcontCitySelectChange={setCity}
              city={city}
            />
          </div>
          <div className="cell medium-6"></div>
        </div>
      </section>
      <hr />
      <section className="relative">
        <div className="grid-x grid-margin-x">
          <div className="cell">
            <h4>Общи данни</h4>
          </div>
          <div className="cell medium-6">
            <label>Дата на раждане</label>
            <input
              type="date"
              value={approxDob || ""}
              onChange={(e) => setApproxDob(e.target.value)}
            />
          </div>
          <div className="cell medium-6">
            <div className="callout small-padding-mobile mobile-small-font tertiary">
              <p>
                Кога е родено котето? Дори и да не знаеш точната дата, попълни
                предполагаема такава.
              </p>
            </div>
          </div>
        </div>

        <div className="grid-x grid-margin-x">
          <div className="cell medium-6">
            <label>Пол</label>
            <div>
              <input
                type="radio"
                value="male"
                name="gender"
                checked={gender == "male"}
                onChange={(e) => setGender(e.target.value)}
              />{" "}
              Момче &nbsp;&nbsp;
              <input
                type="radio"
                value="female"
                name="gender"
                checked={gender == "female"}
                onChange={(e) => setGender(e.target.value)}
              />{" "}
              Момиче &nbsp;&nbsp;
              <input
                type="radio"
                value="multiple"
                name="gender"
                checked={gender == "multiple"}
                onChange={(e) => setGender(e.target.value)}
              />{" "}
              Различни &nbsp;&nbsp;
              <input
                type="radio"
                value="unclear"
                name="gender"
                checked={gender == "unclear"}
                onChange={(e) => setGender(e.target.value)}
              />{" "}
              Неопределен
            </div>
          </div>
        </div>

        <br />
        <div className="grid-x grid-margin-x">
          <div className="cell medium-6">
            <label style={{ marginBottom: 8 }}>Здравна история</label>
            <div className="terms-checks">
              <input
                type="checkbox"
                className="no-margin"
                checked={vaccinated}
                onChange={(e) => setVaccinated(!vaccinated)}
              />{" "}
              <span>Ваксинирано</span>
            </div>
            <br />
            <div className="terms-checks">
              <input
                type="checkbox"
                className="no-margin"
                checked={dewormed}
                onChange={(e) => setDewormed(!dewormed)}
              />{" "}
              <span>Обезпаразитено</span>
            </div>
            <br />
            <div className="terms-checks">
              <input
                type="checkbox"
                className="no-margin"
                checked={neutered}
                onChange={(e) => setNeutered(!neutered)}
              />{" "}
              <span>Кастрирано</span>
            </div>
            <br />
            <div className="terms-checks">
              <label>Тест FeliD-3 (панелевкопения/корона/гиардия)</label>
              <br />
              <input
                type="radio"
                value={""}
                checked={felid_3_negative == null}
                name="felid_3_negative"
                onChange={(e) => setFelid_3_negative(null)}
              />{" "}
              Не е тествано <br />
              <input
                type="radio"
                value={false}
                checked={felid_3_negative == false}
                name="felid_3_negative"
                onChange={(e) => setFelid_3_negative(false)}
              />{" "}
              Позитивен тест <br />
              <input
                type="radio"
                value={true}
                checked={felid_3_negative == true}
                name="felid_3_negative"
                onChange={(e) => setFelid_3_negative(true)}
              />{" "}
              Негативен тест <br />
            </div>
            <br />
            <div className="terms-checks">
              <label>Тест FIV/FeLV (спин и левкемия)</label>
              <br />
              <input
                type="radio"
                value={""}
                checked={fiv_felv_negative == null}
                name="fiv_felv_negative"
                onChange={(e) => setFiv_felv_negative(null)}
              />{" "}
              Не е тествано <br />
              <input
                type="radio"
                value={false}
                checked={fiv_felv_negative == false}
                name="fiv_felv_negative"
                onChange={(e) => setFiv_felv_negative(false)}
              />{" "}
              Позитивен тест <br />
              <input
                type="radio"
                value={true}
                checked={fiv_felv_negative == true}
                name="fiv_felv_negative"
                onChange={(e) => setFiv_felv_negative(true)}
              />{" "}
              Негативен тест <br />
            </div>
          </div>
        </div>

        <br />
        <br />
        <div className="grid-x grid-margin-x">
          <div className="cell medium-6">
            <KittenColorPicker setFilteredColors={setColors} colors={colors} />
          </div>
        </div>
      </section>

      <hr />

      <section className="relative">
        <div className="grid-x grid-margin-x">
          <div className="cell">
            <h4>Снимки</h4>
          </div>
          <div className="cell medium-6">
            <label htmlFor="kitten_images" className="button block-mobile-only">
              {" "}
              <i className="fa fa-cloud-upload"></i> Добави снимка
            </label>
            <input
              id="kitten_images"
              type="file"
              accept="image/png,image/jpeg"
              multiple={false}
              className="show-for-sr"
            />

            <ImagePreview
              images={[...images, ...existingImages]}
              resource={"kitten"}
              addNewBlob={addNewBlob}
              ratio={"4/5"}
            />
            {totalImagesCount < 4 ? (
              <div>
                <p>Добави 4 или повече снимки на котето</p>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="cell medium-6">
            <div className="callout small-padding-mobile mobile-small-font tertiary">
              <p>
                Снимките са изключително важни за осиновителите. Опитът ни от
                1500 публикации и 200+ осиновени котета показва, че за
                осиновителите са нужни минимум 4 снимки за да припознаят и
                пожелаят да осиновят коте.
              </p>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section className="relative">
        <div className="grid-x grid-margin-x">
          <div className="cell">
            <h4>Описание</h4>
          </div>
          <div className="cell medium-6">
            <textarea
              style={{ marginBottom: 5 }}
              rows={12}
              value={description || ""}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
            <p
              style={{
                textAlign: "right",
                paddingRight: 10,
                width: "100%",
                maxWidth: "570px",
                color: wordCount >= DESCRIPTION_MIX_WORD_COUNT ? "green" : "red",
              }}
            >
              {wordCount < DESCRIPTION_MIX_WORD_COUNT ? `${wordCount}/${DESCRIPTION_MIX_WORD_COUNT}` : `${wordCount}`}
            </p>
          </div>

          <div className="cell medium-6">
            <div className="callout small-padding-mobile mobile-small-font tertiary">
              <p>
                Сподели с потенциалните осиновители историята на котето. Осиновяването е голяма стъпка за тях и съответно колкото по-детайлна е информацията, толкова по-добре:
                <br/><br/>
                <ul>
                  <li>къде е родено и живяло котето;</li>
                  <li>как е пападнало при теб;</li>
                  <li>прегледано ли е от ветеринарен доктор;</li>
                  <li>изисква ли специални грижи;</li>
                  <li>какви са характерът и поведението му;</li>
                  <li>разбира ли се с други животни и деца;</li>
                  <li>предлагаш ли транспорт на котето до новия му дом;</li>
                  <li>всякаква друга релевантна информация.</li>
                </ul>

                Не включвай телефона си и други контакти в описанието - кандидат-осиновителите ще изпратят своите контакти на теб.
              </p>
            </div>
          </div>
        </div>
      </section>
      <hr />
      <section className="relative">
        <div className="grid-x grid-margin-x">
          <div className="cell">
            <h4>Условия за осиновяване</h4>
          </div>
          <div className="cell medium-6 margin-bottom-30">
            <div className="terms-checks">
              <input
                type="checkbox"
                className="no-margin desaturated"
                checked={true}
              />{" "}
              <span>Кастрация</span>
            </div>
            <br />
            <div className="terms-checks">
              <input
                type="checkbox"
                className="no-margin"
                checked={requires_inside_housing}
                onChange={(e) =>
                  setRequires_inside_housing(!requires_inside_housing)
                }
              />{" "}
              <span>Отглеждане вътре</span>
            </div>
            <br />
            <div className="terms-checks">
              <input
                type="checkbox"
                className="no-margin"
                checked={requires_no_kids}
                onChange={(e) => setRequires_no_kids(!requires_no_kids)}
              />{" "}
              <span>Дом без деца</span>
            </div>
            <br />
            <div className="terms-checks">
              <input
                type="checkbox"
                className="no-margin"
                checked={requires_no_dogs}
                onChange={(e) => setRequires_no_dogs(!requires_no_dogs)}
              />{" "}
              <span>Дом без кучета</span>
            </div>
            <br />
            <div className="terms-checks">
              <input
                type="checkbox"
                className="no-margin"
                checked={requires_no_other_cats}
                onChange={(e) =>
                  setRequires_no_other_cats(!requires_no_other_cats)
                }
              />{" "}
              <span>Дом без други котки</span>
            </div>
          </div>
          <div className="cell medium-6">
            <div className="callout small-padding-mobile mobile-small-font tertiary">
              <p>
                Избери, ако котето налага специални условия към кандидат
                осиновителите, за да им помогнеш да преценят дали котето е
                подходящо за тях.
              </p>
              <p>Кастрацията е задължително условие.</p>
            </div>
          </div>
        </div>
      </section>
      <hr />

      {error.length > 0 && (
        <div className="callout small-padding-mobile mobile-small-font error round">
          <h6>{error.length} грешки</h6>
          <ul>
            {error.map((err) => (
              <li key={Math.random()}>{err}</li>
            ))}
          </ul>
        </div>
      )}
      <section className="relative">
        <div className="grid-x grid-margin-x">
          <div className="cell"></div>
          <div className="cell medium-6">
            {totalImagesCount < 4 ? (
              <div className="callout small-padding-mobile mobile-small-font warning">
                Нужно е котето да разполага поне с 4 снимки за да бъде
                публикувано.
              </div>
            ) : (
              ""
            )}
            {wordCount < DESCRIPTION_MIX_WORD_COUNT ? (
              <div className="callout small-padding-mobile mobile-small-font warning">
                Нужно е котето да разполага с детайлно описание от поне {DESCRIPTION_MIX_WORD_COUNT} думи.
              </div>
            ) : (
              ""
            )}
            <button
              disabled={isLoading}
              className="button wide large"
              onClick={(e) => submit(e)}
            >
              {totalImagesCount < 4 && wordCount < DESCRIPTION_MIX_WORD_COUNT ? (
                <span>Запази</span>
              ) : (
                <span>Публикувай</span>
              )}
            </button>
          </div>
          <div className="cell medium-6">
            <div className="callout small-padding-mobile mobile-small-font tertiary">
              <p>
                Обявата ще бъде публикувана след одобрение от админ в рамките на
                24 часа. Ще получиш имейл когато това се случи.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default KittenForm;
