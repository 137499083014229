import React, { useState, useEffect, useRef } from "react";
import KittenCard from "../kittens/KittenCard.jsx";
import i18n from "../i18n";

const AdoptedKittenCard = (kitten) => {
 return(
    <div className="cell large-2 small-6 kitten-card-cell" id={`${kitten.identifier}`}>
      <div className="kitten-card adoption-card">
        <div className="kitten-card--image skeleton-placeholder--image force-border-radius" style={{backgroundImage: `url(${kitten.main_image_url}), url('/placeholder-circle.png')`}}>
        </div>
      </div>
    </div>
  )
}

const AdoptedKittenList = (props) => {
	const [page, setPage] = useState(1);
	const [kittens, setKittens] = useState([])

	useEffect(() => {
		fetchKittens()
	}, [page]);

	const fetchKittens = () => {
		let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
    return fetch(`/adoptions/async_load?page=${page}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
    })
      .then((response) => response.json())
      .then((data) => {
      	let parsed_kittens = data.map(k => JSON.parse(k))
        setKittens([...kittens, ...parsed_kittens])

				if (data.length == 10) {
        	setPage(page + 1)
        }
      })
      .catch((error) => {
      });
	}
  return (
    <div className="grid-x" id="kitten-list-grid">
      { kittens.map((kitten, index) => <AdoptedKittenCard key={index} {...kitten} />)}
    </div>
  );
};

export default AdoptedKittenList;
