import React from "react";
import i18n from "../i18n";
import ShoppingCartBody from "./ShoppingCartBody.jsx";
import LineItem from "./LineItem.jsx";
import PubSub from "pubsub-js";

export default class ShoppingCart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shopping_cart: this.props.shopping_cart,
      visibility_class: "",
      handle_class: "fa-shopping-cart",
      handle_animation_class: "",
      handle_count_class: "",
    };

    this.triggerShoppingCartToggle = this.triggerShoppingCartToggle.bind(this);
    this.updateCart = this.updateCart.bind(this);

    // listens the shopping_cart topic and updates the state with the
    // shopping_cart object that is received
    PubSub.subscribe("shopping_cart", this.updateCart);
  }

  // this is triggered whenever we receive a shopping cart update
  // via the PubSub topic
  updateCart(e, shopping_cart) {
    let handle_animation_class =
      shopping_cart.items_count > 0 && this.state.visibility_class !== "toggled"
        ? " active animate__heartBeat "
        : "";
    this.setState({
      shopping_cart: shopping_cart,
      handle_animation_class: handle_animation_class,
    });
  }

  // gets triggered whenever the user clicks the toggler
  // three things happen:
  // - the handle icon changes to a times icon
  // - the shopping cart content gets into the view
  // - the item counter gets hidden
  triggerShoppingCartToggle() {
    if (this.state.visibility_class !== "toggled") {
      this.setState({
        visibility_class: "toggled",
        handle_class: "fa-times",
        handle_count_class: "hidden",
      });
    } else {
      this.setState({
        visibility_class: "hidden",
        handle_class: "fa-shopping-cart",
        handle_count_class: "",
      });
    }
  }

  render() {
    // this is needed when there are already items in the cart and the component is freshly loaded
    let handle_animation_class =
      this.state.shopping_cart.items_count > 0 ? "active" : "";

    return (
      <div
        id="shopping-cart"
        className={
          "radius-large " +
          this.state.visibility_class +
          " " +
          this.props.shopping_cart_prop_classes
        }
      >
        <div
          id="shopping-cart--toggler"
          className={
            "animate__animated " +
            this.state.handle_animation_class +
            handle_animation_class +
            " " +
            this.props.toggler_classes
          }
          onClick={this.triggerShoppingCartToggle}
        >
          <span
            id="shopping_cart--toggler--item-count"
            className={"global-weight-bold " + this.state.handle_count_class}
          >
            {this.state.shopping_cart.items_count}
          </span>
          <i className={"fa " + this.state.handle_class}></i>
        </div>

        <ShoppingCartBody
          shopping_cart={this.state.shopping_cart}
          quantity_editable={true}
        />

        {this.props.actions_buttons ? (
          <div
            className="grid-x grid-margin-x"
            id="shopping-cart--bottom-button"
          >
            <div className="cell small-6">
              <a
                href="/shop/cart/empty"
                className="button secondary no-margin wide hollow"
              >
                Изчисти
              </a>
            </div>
            <div className="cell small-6 text-right">
              <a href="/shop/payments/new" className="button no-margin wide">
                Плащане
              </a>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
