import React, { useState, useEffect } from "react";
import i18n from "../i18n";
import Select from "react-select";
import AsyncSelect from "react-select/async";

const options = [
  { value: 41, label: "София" },
  { value: 33, label: "Пловдив" },
  { value: 7, label: "Варна" },
  { value: 2, label: "Бургас" },
  { value: 35, label: "Русе" },
];

const styles = {
  container: (provided) => ({
    ...provided,
    padding: 0,
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "white",
    padding: 0,
    borderRadius: 10,
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 10,
  }),
  option: (provided, state) => ({
    ...provided,
    padding: 12,
    fontSize: 15,
  }),
};

const EcontCitySelect = (props) => {
  const [city, setCity] = useState(props.city || "");
  const [cities, setCities] = useState([]);

  // Makse a request to the backend to fetch all cities except the ones
  // that were passed to the components from the payments controller
  const fetchAllCities = () => {
    // we check the cities state before making request to avoid making the same
    // call to the backend as the used types in
    if (cities.length == 0) {
      let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
      return fetch("/shop/econt_cities", {
        method: "GET",
        headers: {
          "Content-Type": "application/json;",
          "X-CSRF-Token": csrf,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // the response from the backend is passed to the state so we can reuse it
          setCities(data);
        })
        .catch((error) => {
          console.log("Cities fetch error", error);
        });
    }
  };

  // filters out the cities in the state using the user's input
  const filter = (input) => {
    return cities.filter((city) =>
      city.label.toLowerCase().includes(input.toLowerCase())
    );
  };

  // that promise is activated on if the user starts typing
  // https://react-select.com/async#defaultoptions
  const promiseOptions = (input) => {
    const promise = new Promise((resolve) => {
      resolve(fetchAllCities());
    });
    return promise.then((response) => {
      return filter(input);
    });
  };

  return (
    <>
      <label>Град</label>
      <AsyncSelect
        cacheOptions
        styles={styles}
        defaultOptions={options}
        defaultValue={city}
        loadOptions={promiseOptions}
        onChange={(city) => {
          setCity(city);
          props.handleEcontCitySelectChange(city);
        }}
        placeholder={"Избери (пиши за търсене)"}
        classNamePrefix={"react-select"}
        className={"react-select-container"}
      />
    </>
  );
};

export default EcontCitySelect;
