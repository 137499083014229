import React, { useEffect } from "react";

const ShortText = (props) => {
  const { input, formValues, setFormValues } = props;
  const getInputFormat = () => {
    switch (input.format) {
      case "free":
        return "text";
      case "date":
        return "date";
      case "numbers":
        return "number";
      default:
        return "text";
    }
  };

  useEffect(() => {
    if (!input.required) {
      setFormValues({
        ...formValues,
        [`${input.label_local} (${input.id})`]: "",
      });
    }
  }, []);

  return (
    <div>
      <label>
        {input.label_local}
        {input.required ? "*" : ""}
      </label>
      <input
        type={getInputFormat()}
        name={input.label_local}
        required={input.required}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            [`${input.label_local} (${input.id})`]: e.target.value,
          })
        }
      />
    </div>
  );
};

export default ShortText;
