import React from "react";
import PubSub from "pubsub-js";

export default class QuantityUpdater extends React.Component {
  constructor(props) {
    super(props);

    this.updateQuantity = this.updateQuantity.bind(this);
  }

  updateQuantity(e) {
    e.preventDefault(e);

    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
    let quantity = this.props.sign == "plus" ? 1 : -1;

    fetch("/shop/cart", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        shopping_cart: {
          quantity: quantity,
          variant_id: this.props.variant_id,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ button_state: "" });
        // sends the update shipping_cart object to the shopping_cart topic
        PubSub.publish("shopping_cart", data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <a
        href="#"
        className="shopping-cart--quantity-control"
        onClick={this.updateQuantity}
      >
        <i className={"fa fa-" + this.props.sign + "-circle"}></i>
      </a>
    );
  }
}
