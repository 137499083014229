import React from "react";
import { DirectUpload } from "@rails/activestorage";
import ImageCropper, {getCroppedImg}  from "./ImageCropper";

export default class ImagePreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      images: this.props.images || [],
      currentUpload: null,
      file: null,
      orientation: "",
      fileStatus: "waiting"
    };

    this.handleChange = this.handleChange.bind(this);
    this.sendDeleteRequest = this.sendDeleteRequest.bind(this);
    this.getUrl = this.getUrl.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
    this.fileStatusContent = this.fileStatusContent.bind(this);
    this.onCropClose = this.onCropClose.bind(this);
  }

  componentDidMount() {
    const input = document.querySelector("input[type=file]");
    input.addEventListener("change", (event) => {
      this.setState({fileStatus: "uploading"})
      Array.from(event.target.files).forEach((file) =>
        this.uploadBeforeCrop(file)
      );
      input.value = null;
    });
  }

  uploadBeforeCrop(file) {
    const url = window.location.origin + "/rails/active_storage/direct_uploads"
    const upload = new DirectUpload(file, url);

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
        console.log(error);
      } else {
        // make a request to get the image's url and set it in the state
        this.getUrlBeforeCrop(blob.signed_id, file.name);
      }
    });
  }

  handleChange(blob_id, url) {
    const prevImages = this.state.images;
    const image = { id: blob_id, url: url };

    this.setState({ images: [...prevImages, image] });
  }

  uploadFile(file, input) {
    //const url = input.dataset.directUploadUrl; doesnt work for inputs in react form
    const url = window.location.origin + "/rails/active_storage/direct_uploads"
    const upload = new DirectUpload(file, url);

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
        console.log(error);
      } else {
        // make a request to get the image's url and set it in the state
        this.getUrl(blob.signed_id);
        try {
          this.props.addNewBlob(blob.signed_id);
        } catch {
          console.log("");
        }

        // Add a hidden input to the form with a
        //  value of blob.signed_id so that the blob ids will be
        //  transmitted in the normal upload flow
        const hiddenField = document.createElement("input");
        hiddenField.name = `${this.props.resource}[images][]`;
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        let form = document.querySelector("form");
        if (form) {
          form.appendChild(hiddenField);
        }
        this.setState({ file: null });
      }
    });
  }

  delete(attachment_id) {
    if (typeof attachment_id === "number") {
      this.sendDeleteRequest(attachment_id);
    }
    this.removeImageFromState(attachment_id);
  }

  sendDeleteRequest(attachment_id) {
    $.ajax({
      beforeSend: (xhr) => {
        xhr.setRequestHeader(
          "X-CSRF-Token",
          $('meta[name="csrf-token"]').attr("content")
        );
      },
      url: `/images/${attachment_id}`,
      type: "DELETE",
    });
  }

  removeImageFromState(attachment_id) {
    const images = this.state.images.filter(
      (imageObject) => imageObject.id != attachment_id
    );

    this.setState({ images: images });
  }

  copyButton(url) {
    return <a onClick={() => navigator.clipboard.writeText(url)}>copy url</a>;
  }

  getUrl(signed_blob_id) {
    let url = `/images/${signed_blob_id}/get_s3_url`;
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;

    fetch(url, {
      headers: { "Content-Type": "application/json;", "X-CSRF-Token": csrf },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        this.setState({fileStatus: "ready"})
        this.handleChange(signed_blob_id, response.url);
      });
  }

  getUrlBeforeCrop(signed_blob_id, name) {
    let url = `/images/${signed_blob_id}/get_s3_url_and_analyze`;
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;

    fetch(url, {
      headers: { "Content-Type": "application/json;", "X-CSRF-Token": csrf },
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        this.setState({fileStatus: "uploaded"})
         const image = { name: name, url: response.url};
         this.setState({currentUpload: image});
         this.setState({orientation: response.orientation})
      });
  }

onCropComplete(croppedArea, croppedAreaPixels) {
  getCroppedImg(this.state.currentUpload.url, croppedAreaPixels)
  .then((croppedImage) => {
      // check if ceopped image size is more than 100kb
      if (croppedImage.size > 100000) {
        const file = new File([croppedImage], this.state.currentUpload.name)
        this.setState({ file, croppedImage });
      }
  })
  .catch((error) => console.error('Error occurred:', error));
}

//  async onCropComplete(croppedArea, croppedAreaPixels) {
//   const croppedBlob = await getCroppedImg(this.state.currentUpload.url, croppedAreaPixels);
//   const file = new File([croppedBlob], this.state.currentUpload.name)
//   this.setState({ file: file });
//  }

  onCropClose() {
    this.setState({fileStatus: "waiting"})
  }

  fileStatusContent(status) {
    if (status == 'waiting') {
      return { text: "", icon: ""}
    }
    if (status == 'uploading') {
      return { text: "Снимката се качва от устройството.", icon: "fa-solid fa-image fa-flip"}
    }
    if (status == 'uploaded') {
      return { text: "Снимката се обработва и запазва.", icon: "fa-solid fa-gear fa-spin"}
    }
    if (status == 'ready') {
      return { text: "", icon: ""}
    }
  };

  render() {
    return (
      <div className="grid-x">
        <div className={`file-status ${this.state.fileStatus}`}>
          <div className="grid-x align-middle">
            <div className="cell small-3 text-center">
              <i className={this.fileStatusContent(this.state.fileStatus)['icon']}></i>
            </div>
            <div className="cell small-9">
              {this.fileStatusContent(this.state.fileStatus)['text']}
            </div>
          </div>
        </div>
        {this.state.currentUpload &&
         <ImageCropper
           imageUrl={this.state.currentUpload.url}
           orientation={this.state.orientation}
           onCropComplete={this.onCropComplete}
           onCropClose={this.onCropClose}
           onSave={() => this.uploadFile(this.state.file, "")}
          />
          }
        {this.state.images.length > 0 &&
          this.state.images.map((image, index) => (
            <div
              key={index}
              className="cell medium-3 small-4 image-upload-thumb relative"
            >
              <a
                className="delete-gallery-image-button"
                onClick={(e) => this.delete(image.id)}
              >
                <i className="fa fa-times-circle"></i>
              </a>
              <img src={image.url} />
              {this.props.show_url && this.copyButton(image.url)}
            </div>
          ))}
      </div>
    );
  }
}
