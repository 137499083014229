import React, { useEffect, useState } from "react";
import PubSub from "pubsub-js";

// receives count from UnseenMessagesCount via PubSub
const UnseenMessagesCountSubscriber = (props) => {
		const [count, setCount] = useState(0)

	  useEffect(() => {
	  	getData();
	  }, [])

	  const getData = () => {
	    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
	    return fetch(`/dashboard/profile/me/unseen_messages_count`, {
	      method: "GET",
	      headers: {
	        "X-CSRF-Token": csrf,
	      },
	    }).then((res) => {
	      if (res.status == 200) {
	        res.json().then(json => {
	          setCount(json.count);
	          PubSub.publish("unseen_messages_count", json.count);
	        });
	    } else {
	      res.json().then(json => {
	        console.log(json);
	      });
	    }
	    })
	  }

		return count > 0 ? (
    	<span className="unseen-item-count">{count}</span>
  	) : null
  
}

export default UnseenMessagesCountSubscriber;