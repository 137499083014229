// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("packs/chart")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "foundation-sites"
import autosize from 'autosize'

$(document).on('turbolinks:load', function() {
  $(document).foundation()

  $('.scroll_to').click(function(event){
    event.preventDefault(event);
    $('html, body').animate({scrollTop: $($(this).attr('href')).offset().top }, 650);
  });

  if (!Foundation.MediaQuery.atLeast('small')) {
    $('.sticky').foundation('_destroy');
  }

  $('#dismiss-meme-generator').click(function(e){
    e.preventDefault(e);
    $('#ais-banner').slideUp();
  });

  $('.gallery-thumb, .gallery-thumb-trigger').click(function(e){
    e.stopPropagation();
    e.preventDefault();
    var new_src_horizontal = $(this).attr('data-src');
    var new_src_vertical = $(this).attr('data-src-vertical');
    var new_meme_link = $(this).attr('data-meme-link')

    $('#gallery-main').children().eq(0).attr("srcset", new_src_horizontal)
    $('#gallery-main').children().eq(1).attr("srcset", new_src_vertical)
    $('#gallery-meme-button').attr("href", new_meme_link)
  });

  toggleHiddenNav('#toggle-nav', 'nav ul');
  toggleHiddenNav('#toggle-nav', '#sidebar');

  function toggleHiddenNav(trigger, nav) {
    $(trigger).click(function(e){
      e.stopPropagation();
      e.preventDefault();
      var elem = $(nav)
      if(elem.css('display') == 'none') {
        elem.slideDown(200);
      }
      else {
        elem.slideUp(200);
      }
    });
  }

  //automatically adjusting the size of a textarea with a class autosize
  autosize(document.querySelector('.autosize'));


  // functionality related to showing blurred image
  // before displaying the high quality image

  const objects = document.getElementsByClassName('low_quality');
  Array.from(objects).map((item) => {

    // Start loading image
    let dataset_image = new Image();
    dataset_image.src = item.dataset.src;

    // Once image is loaded replace the src of the low quality image
    // remove the class that has blur filter and add transition
    dataset_image.onload = () => {

      item.nodeName == "IMG" ?
      item.src = dataset_image.src :
      item.style.setProperty("background-image", `url(${dataset_image.src})`);

      item.classList.remove('low_quality');
      item.style.setProperty("-webkit-transition", "ease-in 0.5s");
    };
  });


  // Showing the uploaded files' titles for cover and logo images
  // of organization
  let cover_image_input = document.querySelector("#care_organization_cover_image")
  let logo_image_input = document.querySelector("#care_organization_logo_image")

  if (cover_image_input) {
    cover_image_input.onchange = function(){
      document.querySelector("#cover_image_name").textContent = this.files[0].name;
    }
  }

  if (logo_image_input) {
    logo_image_input.onchange = function(){
      document.querySelector("#logo_image_name").textContent = this.files[0].name;
    }
  }

  $('#image_id').change(function(e){
     e.stopPropagation();
     e.preventDefault();

    $('#image_id option').each(function() {
      if($(this).is(':selected')) {
        document.querySelector("#selected_image").src = this.dataset.src
      }
    });
  });

  // renders a chart for the price breakdown

  var ctx = document.getElementById('price-breakdown-chart');
  if (ctx != null) {
    const data = {
      labels: [],
      datasets: [
        {
          label: 'Dataset 1',
          data: [ctx.dataset.base, ctx.dataset.donation, ctx.dataset.vat],
          backgroundColor: ['#3a556f', '#FC7E89', '#bbb'],
        }
      ]
    };
    var myChart = new Chart(ctx, {
      type: 'pie',
      data: data,
      options: {
        responsive: true,
      },
    });
  }



  // renders a chart for the price breakdown
  var ctx = document.getElementById('applications-by-photos');
  if (ctx != null) {
    const data = {
      labels: [],
      datasets: [
        {
          label: 'Dataset 1',
          data: [ctx.dataset.count, ctx.dataset.candidates],
          backgroundColor: ['#3a556f', '#FC7E89',],
        }
      ]
    };
    var myChart = new Chart(ctx, {
      type: 'pie',
      data: data,
      options: {
        responsive: true,
      },
    });
  }


  // renders a chart for the breakdown of photos per adoptions breakdown
  var ctx = document.getElementById('adoptions-by-photos-count');
  if (ctx != null) {


    (async function() {
      const data = [
        { photo_count:1, adoptions: 0}, 
        { photo_count:2, adoptions: 3}, 
        { photo_count:3, adoptions: 10}, 
        { photo_count:4, adoptions: 24}, 
        { photo_count:5, adoptions: 37}, 
        { photo_count:6, adoptions: 42}, 
        { photo_count:7, adoptions: 28}, 
        { photo_count:8, adoptions: 19}, 
        { photo_count:9, adoptions: 11}, 
        { photo_count:10, adoptions:7}, 
        { photo_count:12, adoptions:7}, 
        { photo_count:13, adoptions:3}, 
        { photo_count:15, adoptions:4}, 
        { photo_count:16, adoptions:2}, 
        { photo_count:18, adoptions:2}, 
      ];

      new Chart(
        ctx,
        {
          type: 'bar',
          data: {
            labels: data.map(row => row.photo_count),
            datasets: [
              {
                label: 'Брой осиновени котета спрямо брой снимки',
                data: data.map(row => row.adoptions),
                backgroundColor: 
                [
                  '#FC7E89', 
                  '#FC7E89', 
                  '#FC7E89', 
                  '#FC7E89', 
                  '#FC7E89', 
                  '#FC7E89', 
                  '#FC7E89', 
                  '#FC7E89', 
                  '#FC7E89', 
                  '#FC7E89', 
                  '#FC7E89',
                  '#FC7E89',
                  '#FC7E89',
                  '#FC7E89',
                  '#FC7E89'
                ]
              }
            ]
          }
        }
      );
    })();
}


  //
  // START ADOPTION FORMS CHART
  //
  var ctx = document.getElementById('adoption_forms_by_month');
  if (ctx != null) {

  (async function() {
    const data = JSON.parse(ctx.dataset.forms)

    new Chart(
      ctx,
      {
        type: 'bar',
        data: {
          labels: data.map(row => row.month),
          datasets: [
            {
              label: 'Кандидат-осиновители',
              data: data.map(row => row.count),
              backgroundColor: data.map(row => '#2a435c')
            }
          ]
        }
      }
    );
  })();
}
//
// END ADOPTION FORMS CHART
//



  var ctx = document.getElementById('photos-by-kittens');
  if (ctx != null) {


    (async function() {
      const data = [
        { average_count: 3.67, adoptions: '0'}, 
        { average_count: 5.05, adoptions: '1'}, 
        { average_count: 6.07, adoptions: '1+'}, 
      ];

      new Chart(
        ctx,
        {
          type: 'bar',
          data: {
            labels: data.map(row => row.adoptions),
            datasets: [
              {
                label: 'Кандидат осиновители спрямо брой снимки',
                data: data.map(row => row.average_count),
                backgroundColor: 
                [
                  '#3a556f', 
                  '#FC7E89', 
                  '#FC7E89', 
                ]
              }
            ]
          }
        }
      );
    })();
}

  // shows/hides the select for the adopter upon achricing an ad
  $('input:radio[name="kitten[faith]"]').change(
    function(){
      if ($('#kitten_faith_adopted_purrs').is(':checked')) {
            $('#kitten_adoption_form_id').removeClass('hidden')
            $('#kitten_adoption_form_id').prop("required", true);
        } else {
          $('#kitten_adoption_form_id').val('');
          $('#kitten_adoption_form_id').addClass('hidden');
          $('#kitten_adoption_form_id').prop("required", false);
        }
    });
});


// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
