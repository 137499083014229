import React from "react";
import i18n from "../i18n";

export default class Period extends React.Component {
  render() {
    let { startDate, endDate } = this.props;

    return (
      <div>
        <h4>{i18n.t("booking_period_title")}</h4>
        {this.props.errors.period}
        <div>
          <label>{i18n.t("start_date")}</label>
          {this.props.errors.startDate}
          <input
            type="date"
            name="startDate"
            placeholder="dd/mm/yyyy"
            value={startDate}
            onChange={this.props.handleChange}
          />

          <label>{i18n.t("end_date")}</label>
          {this.props.errors.endDate}
          <input
            type="date"
            name="endDate"
            placeholder="dd/mm/yyyy"
            value={endDate}
            onChange={this.props.handleChange}
          />
        </div>
      </div>
    );
  }
}
