import React, { useState, useEffect } from "react";

const CookieConsent = (props) => {
  const [consented, setConsented] = useState("");
  const [analytics, setAnalytics] = useState(true);

  const submitConsentAll = () => {
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
    return fetch("/cookies_consents", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        cookies_consent: {
          analytics: true,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setConsented(true);
        console.log(data);
      })
      .catch((error) => {
        console.log("Cookie consent error", error);
      });
  };

  const submitConsentNone = () => {
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
    return fetch("/cookies_consents", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        cookies_consent: {
          analytics: false,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setConsented(true);
        console.log(data);
      })
      .catch((error) => {
        console.log("Cookie consent error", error);
      });
  };

  return (
    <>
      <div
        id="cookie-consent-container"
        style={{ display: consented == true ? "none" : "block" }}
      >
        <div className="grid-container">
          <div className="grid-x align-middle">
            <div className="cell medium-3">
              {/*<input
                type="checkbox"
                className="inverse no-margin"
                onChange={() => setAnalytics(!analytics)}
                checked={analytics}
              />*/}
              <a className="button small white no-margin" onClick={() => submitConsentAll()}>Приемам</a>&nbsp;
              <a className="button small white hollow no-margin" onClick={() => submitConsentNone()} >Отхвърлям</a>
            </div>
            <div className="cell medium-9">
              <p className="no-margin">Purrs.bg използва бисквитки за да предлага някои функционалности и за аналитични цели. Може да научиш повече в <a href="https://purrs.bg/Политика за бисквитки_purrs.bg_clean.pdf" className="color-white global-weight-bold" target="_blank">политиката за бисквитки</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookieConsent;
