import React from "react";
import CardDetails from "../payments/CardDetails.jsx";
import i18n from "../i18n";

export default class PaymentFormOld extends React.Component {
  constructor(props) {
    super(props);

    this.emailError = i18n.t("email_error");

    this.state = {
      error: {},
      step: 1,
      isAddressEditing: true,
      isQuantityEditing: true,
      name: this.props.name || "",
      email: this.props.email || "",
      phone: "",
      amount: this.props.amount,
      payment_intent_id: "",
      address_id: 2,
      address: {},
      secret: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.updateAmount = this.updateAmount.bind(this);
    this.updateDeliveryInfo = this.updateDeliveryInfo.bind(this);
    this.createOrder = this.createOrder.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.createPaymentIntent = this.createPaymentIntent.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  componentDidMount() {
    console.log(this.props.line_items);
    PubSub.subscribe("address_editing_state", (e, state) =>
      this.setState({ isAddressEditing: state })
    );
    PubSub.subscribe("quantity_editing_state", (e, state) =>
      this.setState({ isQuantityEditing: state })
    );
    PubSub.subscribe("updated_amount", this.updateAmount);
    PubSub.subscribe("updated_delivery_info", this.updateDeliveryInfo);
  }

  updateAmount(е, amount) {
    let re = /[\d\.]+/;
    let [parsedAmount] = amount.match(re);

    this.setState({ amount: parsedAmount }, () =>
      this.attemptCreatePaymentIntent()
    );
  }

  updateDeliveryInfo(е, info) {
    this.setState(info, () => this.attemptCreatePaymentIntent());
  }

  attemptCreatePaymentIntent() {
    if (this.isFormValid()) {
      this.createPaymentIntent();
    }
  }

  onSuccess() {
    console.log("successful payment");
    this.createOrder();
  }

  createPaymentIntent() {
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;

    fetch("/shop/payments/create_payment_intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        email: this.state.email,
        amount: this.state.amount,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        this.setState({
          secret: response.secret,
          payment_intent_id: response.intent_id,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  createOrder() {
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;

    let orderObject = {
      payment_total: this.state.amount,
      address: JSON.stringify(this.state.address),
      line_items: this.props.line_items,
      payment_intent_id: this.state.payment_intent_id,
      payment_state: "completed",
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
    };

    fetch("/shop/orders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({ order: orderObject }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if ((response.result = "success")) {
          window.location.href = response.redirect_url;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Form validation
  isFormValid() {
    let validAmount = this.state.amount > 0;
    return !!(this.state.email && validAmount);
  }

  addError(error) {
    this.setState({ error: { ...this.state.error, ...error } });
  }

  clearError(field) {
    this.setState({ error: { ...this.state.error, ...{ [field]: "" } } });
  }

  render() {
    if (this.state.isQuantityEditing || this.state.isAddressEditing) {
      return (
        <div>
          <p>{i18n.t("inactive_payment_form_text")}</p>
        </div>
      );
    }

    return (
      <div className="relative">
        <CardDetails
          name={this.state.name}
          amount={this.state.amount}
          secret={this.state.secret}
          stripe_pk={this.props.stripe_pk}
          onSuccess={this.onSuccess}
          handleChange={this.handleChange}
          paymentButtonText={i18n.t("pay_button", {
            amount: this.state.amount,
          })}
        />
      </div>
    );
  }
}
