import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import i18n from "../i18n";

export default class CardDetails extends React.Component {
  constructor(props) {
    super(props);

    const stripeOptions = this.props.stripe_acc ? { stripeAccount: this.props.stripe_acc } : {};

    this.stripe = loadStripe(this.props.stripe_pk, stripeOptions);

    this.state = {
      error: "",
      paymentInProgress: false,
      name: this.props.name,
      secret: this.props.secret,
      success: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCardChange = this.handleCardChange.bind(this);
    this.executePayment = this.executePayment.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.secret != prevProps.secret ||
      this.props.name != prevProps.name
    ) {
      this.setState({
        name: this.props.name,
        secret: this.props.secret,
      });
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleCardChange(e) {
    this.setState({ error: e.error });
  }

  executePayment(stripe, elements) {
    const cardElement = elements.getElement("card");
    this.setState({ paymentInProgress: true });
    stripe
      .confirmCardPayment(this.state.secret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: this.state.name,
          },
        },
      })
      .then((result) => {
        if (result.error) {
          this.setState({ error: result.error });
          this.setState({ success: false });
          this.setState({ paymentInProgress: false });
        } else {
          this.props.onSuccess();
          this.setState({ success: true });
        }
      });
  }

  renderPayButton(stripe, elements) {
    let buttonText =
      this.props.paymentButtonText ||
      i18n.t("donate_button", { amount: this.props.amount });

    return (
      <div className="payment-button-wrapper">
          <a
            className="button wide"
            onClick={() => this.executePayment(stripe, elements)}
          >
            {buttonText}
          </a>
      </div>
    );
  }

  render() {
    const cardOptions = {
      style: {
        base: {
          fontSize: "16px",
          color: "#424770",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    };

    if (this.state.success) {
      return (
        <div className="grid-x grid-margin-x align-middle">
          <div className="cell medium-3 small-4">
            <div className="success-checkmark">
              <div className="check-icon">
                <span className="icon-line line-tip"></span>
                <span className="icon-line line-long"></span>
                <div className="icon-circle"></div>
                <div className="icon-fix"></div>
              </div>
            </div>
          </div>
          <div className="cell medium-9 small-8">
            <p className="no-margin">
             {this.props.successText}
            </p>
          </div>
        </div>
      )
    }

    return (
      <div>
        {this.state.paymentInProgress && (
          <div className="grid-x grid-margin-x align-middle">
            <div className="cell medium-3 small-4">
              <div className="clock-loader"></div>
            </div>
            <div className="cell medium-9 small-8">
              <p className="no-margin">Плащането се обработва...</p>
            </div>
          </div>
        )}

        <Elements stripe={this.stripe}>
          <ElementsConsumer>
            {({ stripe, elements }) => (
              <form style={{display: this.state.paymentInProgress ? "none" : "block"}}>
                <div>
                  <label>{i18n.t("cardholder_name")}</label>
                  <input
                    type="text"
                    name="name"
                    value={this.state.name}
                    onChange={this.props.handleChange}
                  />
                </div>

                <label>{i18n.t("credit_or_debit_card")}</label>
                <div className="card-element-wrapper">
                  <CardElement
                    onChange={this.handleCardChange}
                    options={cardOptions}
                  />
                </div>

                <div className="payment-error-wrapper">
                  {this.state.error && i18n.t(this.state.error.code)}
                </div>

                {this.renderPayButton(stripe, elements)}
              </form>
            )}
          </ElementsConsumer>
        </Elements>
      </div>
    );
  }
}
