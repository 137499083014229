import React, { useState, useEffect, useRef } from "react";
import CardDetails from "../payments/CardDetails.jsx";
import i18n from "../i18n";

const emailError = i18n.t("email_error");

const PaymentForm = ({
  user,
  initialAmount,
  stripe_pk,
  line_items,
  delivery_type,
  delivery_price,
}) => {
  const [isAddressEditing, setIsAddressEditing] = useState(true);
  const [name, setName] = useState(user.name || "");
  const [amount, setAmount] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [secret, setSecret] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const divRef = useRef(null);
  const [refVisible, setRefVisible] = useState(false);

  const executeScroll = () => {
    divRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  useEffect(() => {
    PubSub.subscribe("quantity_editing_state", (e, state) =>
      setIsAddressEditing(state)
    );
    PubSub.subscribe("updated_delivery_info", updateDeliveryInfo);
  }, []);

  useEffect(() => {
    console.log("refVisible", refVisible);
    if (!refVisible || success) {
      return;
    }
    executeScroll();
  }, [refVisible]);

  const handleChange = (e) => {
    // currently only name is handled
    const { name, value } = e.target;
    setName(value);
  };

  const updateDeliveryInfo = async (e, info) => {
    setPaymentIntentId(info.paymentIntentId);
    setSecret(info.secret);
    setAmount(info.amount);
  };

  const addError = (error) => {
    setError({ error: { ...error, ...error } });
  };

  const clearError = (field) => {
    setError({ error: { ...error, ...{ [field]: "" } } });
  };

  const onSuccess = () => {
    console.log("successful payment");
    createOrder();
  };

  const createOrder = () => {
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;

    let orderObject = {
      payment_intent_id: paymentIntentId,
      payment_state: "completed",
    };

    fetch("/shop/orders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({ order: orderObject }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if ((response.result = "success")) {
          PubSub.publish("payment_state", false);
          setSuccess(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (isAddressEditing) {
    return (
      <div>
        <p>{i18n.t("inactive_payment_form_text")}</p>
      </div>
    );
  }

  return (
    <div className="relative">
      <div
        ref={(ref) => {
          divRef.current = ref;
          setRefVisible(!!ref);
        }}
      ></div>

      <CardDetails
        name={name}
        amount={amount}
        secret={secret}
        stripe_pk={stripe_pk}
        onSuccess={onSuccess}
        handleChange={handleChange}
        successText={"Поръчката ти е приета! Очаквай имейл с потвърждение."}
        paymentButtonText={i18n.t("pay_button", { amount: amount })}
      />
    </div>
  );
};

export default PaymentForm;
