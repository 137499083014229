import React from "react";
import NumberOfCats from "./NumberOfCats.jsx";
import Period from "./Period.jsx";
import SitterSelect from "./SitterSelect.jsx";
import Address from "../shared/Address.jsx";
import KeyPickUp from "./KeyPickUp.jsx";
import UserDetails from "./UserDetails.jsx";
import Preview from "./Preview.jsx";
import StepButton from "./StepButton.jsx";
import i18n from "../i18n";
import CardDetails from "../payments/CardDetails.jsx";

// Add payment step
// Add validations
// Add translations

export default class BookCatSitter extends React.Component {
  constructor(props) {
    super(props);

    this.pricePerVisit = 20;
    this.catsCountError = i18n.t("option_missing");
    this.startDateError = i18n.t("start_date_missing");
    this.endDateError = i18n.t("end_date_missing");
    this.periodError = i18n.t("period_error");
    this.sitterError = i18n.t("option_missing");
    this.addressError = i18n.t("address_error");
    this.nameError = i18n.t("name_error");
    this.emailError = i18n.t("email_error");
    this.phoneError = i18n.t("phone_error");
    this.pickUpStartDateConflictError = i18n.t("pick_up_start_date_conflict");
    this.dropOffEndDateConflictError = i18n.t("drop_off_end_date_conflict");
    this.pickUpHourError = i18n.t("pick_up_hour_error");
    this.dropOffHourError = i18n.t("drop_off_hour_error");

    this.state = {
      step: 1,
      redirect_url: "",
      secret: "",
      amount: "",
      visitsCount: "",
      visitDates: [],
      catsCount: "",
      startDate: "",
      endDate: "",
      pickUpDate: "",
      pickUpHour: "",
      dropOffDate: "",
      dropOffHour: "",
      sitter: "",
      address: {
        street: "",
        entrance: "",
        apartment: "",
      },
      user: {
        name: "",
        email: "",
        phone: "",
      },
      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.changeStep = this.changeStep.bind(this);
    this.createBooking = this.createBooking.bind(this);
    this.validateField = this.validateField.bind(this);
    this.calculateVisits = this.calculateVisits.bind(this);
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.step == 2 && this.state.step == 3) {
      this.calculateVisits();
    }
  }

  handleChange(event) {
    const { name, value, type } = event.target;
    this.setState({ [name]: value }, () => {
      type == "radio" ? this.changeStep(this.state.step + 1) : null;
    });
  }

  changeStep(stepNumber) {
    let isStepValid =
      stepNumber > this.state.step ? this.validateStep(this.state.step) : true;
    isStepValid ? this.setState({ step: stepNumber }) : null;
  }

  handleAddressChange(addressChanges) {
    let newAddress = { ...this.state.address, ...addressChanges };
    this.setState({ address: newAddress });
  }

  handleUserChange(userChanges) {
    let updatedDetails = { ...this.state.user, ...userChanges };
    this.setState({ user: updatedDetails });
  }

  createBooking() {
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;

    fetch("/bookings", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        booking: {
          start_date: this.state.startDate,
          end_date: this.state.endDate,
          cats_count: this.state.catsCount,
          sitter: this.state.sitter,
          amount: this.state.amount,
          address: JSON.stringify(this.state.address),
          user: JSON.stringify(this.state.user),
          pick_up: `${this.state.pickUpDate} ${this.state.pickUpHour}`,
          drop_off: `${this.state.dropOffDate} ${this.state.dropOffHour}`,
        },
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        this.setState({
          secret: response.secret,
          redirect_url: response.redirect_url,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  calculateVisits() {
    // calculating how much the client should pay
    // taking into account exclusively the days between the start
    // and end date

    const startDate = new Date(this.state.startDate);
    const endDate = new Date(this.state.endDate);

    let visitDates = this.getVisitDates(startDate, endDate);
    let visits = visitDates.length;
    const totalAmount = visits * this.pricePerVisit;

    this.setState({
      amount: totalAmount,
      visitsCount: visits,
      visitDates: visitDates,
    });
  }

  getVisitDates(start, end) {
    let visitDates = [];
    let dateToAdd = new Date(start);

    for (
      dateToAdd;
      dateToAdd < end;
      dateToAdd.setDate(dateToAdd.getDate() + 1)
    ) {
      visitDates.push(dateToAdd.toLocaleString().split(",")[0]);
    }

    // remove the start date from the visit dates array
    visitDates.shift();
    return visitDates;
  }

  // #### Validations ####

  validateStep(step) {
    switch (step) {
      case 1:
        return this.validateField("catsCount");
      case 2:
        let start = this.state.startDate;
        let end = this.state.endDate;
        return (
          this.validateFields(["startDate", "endDate"]) &&
          this.validatePeriod(start, end) &&
          this.datesAtLeastOneDayApart(start, end)
        );
      case 3:
        return this.validateField("sitter");
      case 4:
        return this.validateField("address");
      case 5:
        return this.validateKeyArrangements();
      case 6:
        return this.validateUser();
      default:
        return true;
    }
  }

  validateKeyArrangements() {
    let pickUpDate = this.state.pickUpDate;
    let pickUpHour = this.state.pickUpHour;
    let dropOffDate = this.state.dropOffDate;
    let dropOffHour = this.state.dropOffHour;

    let startDate = this.state.startDate;
    let endDate = this.state.endDate;

    let isPickUpBeforeStartDate = this.isValidPeriod(pickUpDate, startDate);
    let isDropOffAfterEndDate = this.isValidPeriod(endDate, dropOffDate);

    isPickUpBeforeStartDate
      ? this.clearError("pickUpStartDateConflict")
      : this.addError("pickUpStartDateConflict");
    isDropOffAfterEndDate
      ? this.clearError("dropOffEndDateConflict")
      : this.addError("dropOffEndDateConflict");

    this.isValidHour(pickUpHour)
      ? this.clearError("pickUpHour")
      : this.addError("pickUpHour");
    this.isValidHour(dropOffHour)
      ? this.clearError("dropOffHour")
      : this.addError("dropOffHour");

    return !!(
      isPickUpBeforeStartDate &&
      isDropOffAfterEndDate &&
      this.isValidHour(pickUpHour) &&
      this.isValidHour(dropOffHour)
    );
  }

  validateUser() {
    let name = this.state.user.name;
    let email = this.state.user.email;
    let phone = this.state.user.phone;

    let isPhoneValid = !(isNaN(phone) || this.isEmpty(phone));
    let isNameValid = !this.isEmpty(name);
    let isEmailValid = this.isValidEmail(email);

    isPhoneValid ? this.clearError("phone") : this.addError("phone");
    isEmailValid ? this.clearError("email") : this.addError("email");
    isNameValid ? this.clearError("name") : this.addError("name");

    return !!(isPhoneValid && isEmailValid && isNameValid);
  }

  validatePeriod(startDate, endDate) {
    let valid =
      this.isValidDate(startDate) &&
      this.isValidDate(endDate) &&
      this.isValidPeriod(startDate, endDate);

    valid ? this.clearError("period") : this.addError("period");
    return !!valid;
  }

  validateFields(fields) {
    let results = fields.map((field) => {
      return this.validateField(field);
    });

    return !!results.every((result) => result);
  }

  validateField(name) {
    let valueFromState =
      name == "address" ? this.state.address.street : this.state[name];
    let valid;

    if (this.isEmpty(valueFromState)) {
      this.addError(name);
      valid = false;
    } else {
      this.clearError(name);
      valid = true;
    }

    return !!valid;
  }

  isEmpty(value) {
    let trimmedValue = value.trim();
    return !!(
      trimmedValue == "" ||
      trimmedValue == null ||
      trimmedValue == undefined
    );
  }

  isValidDate(date) {
    let re = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    let reDashed = /^\d{4}\-\d{1,2}\-\d{1,2}$/;

    // checking date formatting - 12/12/2021 || 2021-12-12
    let result = re.test(date) || reDashed.test(date);

    return !!result;
  }

  isValidHour(hour) {
    let re = /^([0-1]\d|20):[0-5]\d$/;
    let result = re.test(hour);

    return !!result;
  }

  isValidPeriod(startDate, endDate) {
    let start = new Date(startDate);
    let end = new Date(endDate);
    let today = new Date();

    // checking if the start date is in the future,
    // as well as if the start date is before or
    // on the same day as the end date
    let valid =
      start > today && (start < end || start.getTime() == end.getTime());

    return !!valid;
  }

  datesAtLeastOneDayApart(start, end) {
    let endDate = new Date(end);
    let dayAfterStart = new Date(start);
    dayAfterStart.setDate(dayAfterStart.getDate() + 1);

    return !!(dayAfterStart < endDate);
  }

  isValidEmail() {
    let re = /\S+@\S+\.\S+/;
    let result = re.test(this.state.user.email);

    return !!result;
  }

  addError(field) {
    let errorName = field + "Error";
    let error = { [field]: this[errorName] };
    this.setState((prevState) => ({
      errors: { ...prevState.errors, ...error },
    }));
  }

  clearError(field) {
    this.setState((prevState) => ({
      errors: { ...prevState.errors, ...{ [field]: "" } },
    }));
  }

  // #### Validations End ####

  render() {
    let {
      catsCount,
      startDate,
      endDate,
      sitter,
      address,
      errors,
      user,
      pickUpDate,
      pickUpHour,
      dropOffDate,
      dropOffHour,
    } = this.state;

    return (
      <div className="grid-x">
        <div className="cell medium-6">
          {this.state.step == 1 && (
            <NumberOfCats
              handleChange={this.handleChange}
              catsCount={catsCount}
              errors={errors}
            />
          )}
          {this.state.step == 2 && (
            <Period
              handleChange={this.handleChange}
              startDate={startDate}
              endDate={endDate}
              errors={errors}
            />
          )}
          {this.state.step == 3 && (
            <SitterSelect
              handleChange={this.handleChange}
              sitter={sitter}
              maleAvatar={this.props.maleAvatar}
              femaleAvatar={this.props.femaleAvatar}
              errors={errors}
            />
          )}
          {this.state.step == 4 && (
            <Address
              handleAddressChange={this.handleAddressChange}
              address={address}
              title={i18n.t("booking_address_title")}
              errors={errors}
            />
          )}
          {this.state.step == 5 && (
            <KeyPickUp
              handleChange={this.handleChange}
              pickUpDate={pickUpDate}
              pickUpHour={pickUpHour}
              dropOffDate={dropOffDate}
              dropOffHour={dropOffHour}
              errors={errors}
            />
          )}
          {this.state.step == 6 && (
            <UserDetails
              handleUserChange={this.handleUserChange}
              user={user}
              errors={errors}
            />
          )}
          {this.state.step == 7 && (
            <Preview state={this.state} createBooking={this.createBooking} />
          )}
        </div>

        <div className="cell medium-12">
          {this.state.step != 1 && (
            <StepButton
              name="previous"
              changeStep={this.changeStep}
              goToStep={this.state.step - 1}
            />
          )}
          {this.state.step != 7 && (
            <StepButton
              name="next"
              changeStep={this.changeStep}
              goToStep={this.state.step + 1}
            />
          )}
        </div>

        {this.state.secret && (
          <CardDetails
            name={this.state.user.name}
            amount={this.state.amount}
            secret={this.state.secret}
            stripe_pk={this.props.stripe_pk}
            redirect_url={this.state.redirect_url}
            paymentButtonText={i18n.t("pay_button", {
              amount: this.state.amount,
            })}
          />
        )}
      </div>
    );
  }
}
