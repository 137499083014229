import React from "react";
import PubSub from "pubsub-js";
import i18n from "../i18n";

export default class AddToCart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      button_state: "",
    };

    this.addProduct = this.addProduct.bind(this);
  }

  // sends the product and quantity info to the backend
  addProduct(e) {
    e.preventDefault(e);
    // this doesn't seem to be needed in prod
    // this.setState({button_state: "clicked"})
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;

    fetch("/shop/cart", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        shopping_cart: {
          quantity: 1,
          variant_id: this.props.variant_id,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ button_state: "" });
        // sends the update shipping_cart object to the shopping_cart topic
        PubSub.publish("shopping_cart", data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div>
        <a
          href="#"
          onClick={this.addProduct}
          className={
            "shopping-cart--add-to-cart-button button large wide " +
            this.state.button_state
          }
        >
          Купи
        </a>
      </div>
    );
  }
}
