import React, { useEffect } from "react";

const Radio = (props) => {
  const { input, formValues, setFormValues } = props;

  useEffect(() => {
    if (formValues[`${input.label_local} (${input.id})`] === undefined) {
      setFormValues({
        ...formValues,
        [`${input.label_local} (${input.id})`]:
          input.options[0].labelLocal || "",
      });
    }
  }, [formValues]);

  return (
    <div
      onChange={(e) =>
        setFormValues({
          ...formValues,
          [`${input.label_local} (${input.id})`]: e.target.value,
        })
      }
    >
      {input.options.map((option, index) => {
        return (
          <span style={{ marginRight: "5px" }} key={`radio-${index}`}>
            <input
              type="radio"
              name="radio_btn"
              value={option.labelLocal}
              defaultChecked={index === 0 ? true : false}
            />{" "}
            {option.labelLocal}
          </span>
        );
      })}
    </div>
  );
};

export default Radio;
