import React from "react";
import i18n from "../i18n";

export default class Address extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      predictions: [],
      showDropdown: false,
      address: this.props.address,
    };

    this.setPredictions = this.setPredictions.bind(this);
    this.autocomplete = this.autocomplete.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.address.city != prevProps.address.city ||
      this.props.address.street != prevProps.address.street ||
      this.props.address.street_number !== prevProps.address.street_number ||
      this.props.address.unit != prevProps.address.unit
    ) {
      this.setState({ address: this.props.address });
    }
  }

  autocomplete(event) {
    const { name, value } = event.target;
    this.props.handleAddressChange({ street: value });
    this.showDropdown();

    new window.google.maps.places.AutocompleteService().getPlacePredictions(
      {
        input: value,
        componentRestrictions: { country: "BG" },
        types: ["address"],
      },
      this.setPredictions
    );
  }

  setPredictions(predictions) {
    console.log(predictions);
    this.setState({ predictions: predictions });
  }

  handleSelect(prediction) {
    this.hideDropdown();

    let street = prediction.description;
    this.props.handleAddressChange({ street: street });
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.props.handleAddressChange({ [name]: value });
  }

  hideDropdown() {
    this.setState({ showDropdown: false });
  }

  showDropdown() {
    this.setState({ showDropdown: true });
  }

  render() {
    let { street, unit, street_number, city } = this.state.address;

    return (
      <div className="grid-x margin-y">
        {this.props.title && (
          <div className="cell">
            <h3>{this.props.title}</h3>
          </div>
        )}

        {this.props.errors.address}

        <div className="cell">
          <label htmlFor="city">{i18n.t("city")}</label>
          <input
            type="text"
            placeholder={"София"}
            name="city"
            onChange={this.handleChange}
            value={city}
            disabled={this.props.isDisabled || false}
          />
        </div>

        <div className="cell relative">
          <label htmlFor="street">{i18n.t("street")}</label>
          <input
            type="text"
            placeholder={"Кутловица"}
            list="predictions"
            value={street}
            onChange={this.autocomplete}
            disabled={this.props.isDisabled || false}
          />

          {this.state.showDropdown && (
            <div className="address-prediction-list">
              <ul>
                {this.state.predictions?.map((prediction) => (
                  <li
                    key={prediction.place_id}
                    onClick={(e) => this.handleSelect(prediction)}
                  >
                    {prediction.description || "Not found"}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className="cell">
          <label htmlFor="street_number">{i18n.t("street_number")}</label>
          <input
            type="text"
            placeholder={"7"}
            name="street_number"
            onChange={this.handleChange}
            value={street_number}
            disabled={this.props.isDisabled || false}
          />
        </div>
        <div className="cell">
          <label htmlFor="unit">{i18n.t("unit")}</label>
          <input
            type="text"
            placeholder={"Б"}
            name="unit"
            onChange={this.handleChange}
            value={unit}
            disabled={this.props.isDisabled || false}
          />
        </div>
      </div>
    );
  }
}
