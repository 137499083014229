import React, { useState, useEffect } from "react";
import i18n from "../i18n";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import EcontCitySelect from "../shop/EcontCitySelect.jsx";

// we are overriding some of default styles here. Overall, it's shitty
// as there also other style defitions in shop.scss since the selects colide
// with the styles from Foundation.
const styles = {
  container: (provided) => ({
    ...provided,
    padding: 0,
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "white",
    padding: 0,
    borderRadius: 10,
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 10,
  }),
  option: (provided, state) => ({
    ...provided,
    padding: 12,
    fontSize: 15,
  }),
};

const emptyEcontOffice = {
  label: "",
  value: "",
  city: "",
};

const EcontOfficeSelector = (props) => {
  const [cityLabel, setCityLabel] = useState("");
  const [offices, setOffices] = useState([]);
  const [office, setOffice] = useState(emptyEcontOffice);

  // makes a request to the backend to fetch the offices for a given city
  const fetchCityOffices = (city) => {
    setCityLabel(city.label);

    // we clear the office state in order to reset any selection in the UI
    setOffice("");
    // we clear the office address in the parent state if one is selected
    props.econtOfficeDelivery.value
      ? props.handleEcontOfficeChange(emptyEcontOffice)
      : null;

    // clears the list of offices again to reset the select
    setOffices([]);
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
    return fetch(`/shop/econt_cities/${city.value}/econt_offices`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setOffices(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div>
        <h3>Доставка до офис на Еконт</h3>
        <EcontCitySelect handleEcontCitySelectChange={fetchCityOffices} />

        <label>Офис или Еконтомат</label>
        <Select
          isDisabled={!cityLabel}
          styles={styles}
          options={offices}
          value={office}
          onChange={(office) => {
            setOffice({...office, ...{city: cityLabel}});
            props.handleEcontOfficeChange({...office, ...{city: cityLabel}});
          }}
          placeholder={!cityLabel ? "Първо избери град" : "Избери"}
          classNamePrefix={"react-select"}
          className={"react-select-container"}
        />
      </div>
    </>
  );
};

export default EcontOfficeSelector;
