import React, { useState } from "react";

const FormActions = (props) => {
  const [type, setType] = useState("");
  const [containerWidth, setContainerWidth] = useState("");
  const [toggleConfirm, setToggleConfirm] = useState("info");
  const [formState, setFormState] = useState("");
  const [rejectionNote, setRejectionNote] = useState("");

  const ContainerWidth = document.getElementById("adoption-form-container").offsetWidth;
  if (ContainerWidth.length) {
    setContainerWidth(ContainerWidth);
  }

  return (
    <div>
      {}
      <div className={`form-action--modal-wrapper ${toggleConfirm == 'info'}`}>
        <div className="form-action--modal">
          <p>Категоризирай кандидат осиновителите в рамките на 24 часа, за да организираме комуникация с тях. </p>
          <p>Избери бутона <span className="label-positive">Подходящ</span> - ако смяташ, че кандидатът е по-скоро подходящ за осиновител за да получиш телефонния му номер и да продължиш комуникация с него. </p>
          <p>Използвай бутона <span className="label-negative">Неподходящ</span>, за да отхвърлиш кандидат. Той автоматично ще получи е-мейл, в който ще го информираме, за да продължи търсенето си сред останалите обяви. </p>
          <div className="grid-x grid-margin-x">
            <div className="cell medium-6">
              <button className="button secondary hollow no-margin" onClick={() => {setToggleConfirm("")}}>Разбрах</button>
            </div>
          </div>
        </div>
      </div>

      <div className={`form-action--modal-wrapper ${toggleConfirm == 'accepted'}`}>
        <div className="form-action--modal">
          <p>Когато избереш бутона ПОДХОДЯЩ, кандидатът ще получи е-мейл, в който ще го известим да очаква обаждане, а ти ще получиш телефонния му номер. Важно е да се свържеш с него възможно най-скоро. </p>

          <p>НЕ ЗАБРАВЯЙ: Ефективната и бърза комуникация е ключова в процеса по намиране на дом за котето.</p>

          <div className="grid-x grid-margin-x">
            <div className="cell medium-6">
              <button className="button success">
                <i className="fa-solid fa-check"></i>
                &nbsp;
                Продължи
              </button>
            </div>
            <div className="cell medium-6">
              <button className="button secondary hollow no-margin" onClick={() => {setToggleConfirm("")}}>Откажи</button>
            </div>
          </div>
        </div>
      </div>
      <div className={`form-action--modal-wrapper ${toggleConfirm == 'rejected'}`}>
        <div className="form-action--modal">
          <p>Когато избереш бутона неподходящ, системата автоматично ще уведоми кандидата за решението ти с е-мейл, без да се налага да се свързваш с него по телефона. </p>

          <p>Необходимо е да посочиш защо смяташ, че кандидатът не е подходящ. Тази информация е само за наша употреба и няма да бъде включена в обратната връзка, която стига до него. </p>

          <label>Причина <small>(не се споделеня с кандидата)</small></label>
          <textarea rows='2' className=""></textarea>
          <div className="grid-x grid-margin-x">
            <div className="cell medium-6">
              <button className="button alert">
                <i className="fa-solid fa-xmark"></i>
                &nbsp;
                Продължи
              </button>
            </div>
            <div className="cell medium-6">
              <button className="button secondary hollow no-margin" onClick={() => {setToggleConfirm("")}}>Откажи</button>
            </div>
          </div>
        </div>
      </div>
      <div id="adoption-form-header" style={{width: ContainerWidth}}>
        <div className="grid-x align-middle">
          <div className="cell small-6">
            <button className='positive' onClick={() => setToggleConfirm('accepted')}>
              <i className="fa-solid fa-check"></i>
              &nbsp;
              Подходящ
            </button>
          </div>
          <div className="cell small-6 text-right">
            <button className='negative' onClick={() => setToggleConfirm('rejected')}>
              <i className="fa-solid fa-xmark"></i>
              &nbsp;
              Неподходящ
            </button>
          </div>
        </div>
      </div>
    </div>
  )

}

export default FormActions