import React, { useState, useEffect } from "react";

// This component provides functionality for authenticating users and solves
// the unique challenge where we dont want users to go through the experience of
// creating a profile and setting up a password but yet we want them to have a profile
// in the BE. The way this works is we take their email, check if there's a user with
// that email and if yes, we send an email, if not, we create one by collecting the info
// needed and then send a verification email.
const AccountCheck = (props) => {

  //////////////////////////
  /// CONSTANTS
  //////////////////////////
  // eventually we'd want move these to an I18n file.
  const INVALID_EMAIL = "Невалиден имейл";
  const INVALID_NAME = "Невалидно име и фамилия";
  const INVALID_PHONE = "Невалиден телефон";
  const INVALID_CODE = "Невалиден код";
  const ATTEMPTED_OR_EXPIRED_CODE = "Изтекъл код или твърде много опити. Изпратихме нов код на посочени имейл.";

  //////////////////////////
  /// STATE
  //////////////////////////
  // used to indicate whether there's an ongoing HTTP request for which
  // we are waiting for a response from
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  
  // We'll use this state to push error messages that we want to show to the user.
  // Making it an array as there could be multiple different errors to show.
  const [errors, setErrors] = useState([]);
  
  // the purpose of this state is to keep a list of sections that we render to the user.
  // The sections correspond to a step in the registration/verification process.
  const [sections, setSections] = useState([])

  //////////////////////////
  /// VALIDATIONS
  //////////////////////////
  // universal function for adding and removing errors
  // this is super dirty but I couldn't figure out a way to do it better
  // essentially, we give an array or arrays where the first element
  // indicates whether the validation fails or not and the second is the error message itself
  // [[true, 'name is blank'], [true, 'email is invalid']]. This allows for messages to be removed
  // if the user has corrected them.
  const handleValidationErrors = (list) => {
    var new_errors = list.map((i) => {
      // if the first elem is true it means the validation  passes
      if (i[0]){
        return;
      // if false, the error message gets added to the new array of errors
      } else {
        return i[1]
      }
      // removes the undefined elements from the array
    }).filter(Boolean);

    setErrors([... new Set(new_errors)]);
  }

  // checks whether the provided value is a valid email. Copy-pasted from here:
  // https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
  const validateEmail = email => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      .test(email);
  };

  // checks whether the provided value is a valid phone
  const validateName = name => {
    return true// /^[A-Za-z]+ [A-Za-z]+$/.test(name);
  };

  const validatePhone = phone => {
    return /^(\+|\d{1,4})\s?(\(\d{1,4}\))?\s?(\d{1,4}\s?[-\/.]?\s?)*\d{1,4}$/.test(phone)
  };

  const validateCode = code => {
    return /^\d{6}$/.test(code)
  };

  //////////////////////////
  /// SAFETY NETs
  //////////////////////////  

  // the functions are responsible for handling unconventional situations
  // whether the user might go back to a previous step instead of moving forward

  // this is needed in order to handle the situation where a user might
  // change the inputted email. In that case, we want to restart the process.
  const changeEmail = email => {
    // remove the verifyca code section
    const index1 = sections.indexOf('verify_code');
    sections.splice(index1, 1);

    // remove the new user code section
    const index2 = sections.indexOf('new_user');
    sections.splice(index2, 1);

    // update the sections state with the updated value
    setSections(sections);

    setEmail(email)
    setPhone("")
    setName("")
    setErrors([])
  }

  // This determines whether the user can edit the inputted name and phone fields
  // if the verify_code section is present we dont' want to allow updating these fields
  // as the changes will not be saved since the button will trigger a different API call, not the one
  // persisting the user fields.
  const enabledEditing = () => {
    return sections.includes("verify_code") ? true : false
  }

  //////////////////////////
  /// API
  //////////////////////////

  // wrapper function that determines which API to call
  // the way this works is, it tracks the portions of the UI that are displayed to the user
  // and determines where the data should go.
  // This design may not be ideal in the future as the current logic where there's only a single button
  // may end up being limiting.
  const triggerRequest = () => {
    setErrors([])
    // a request is always initially sent to check if a user with the provided email exists
    if (sections.length == 0) {
      searchUser();
      return;
    }

    // if the code section exists, the button should make a request to verify it
    // regardless if that's an existing user or a freshly created one
    if (sections.includes("verify_code")) {
      verifyCode();
      return;
    }

    // if no user record exists, extra fields are loaded and the visitor submits extra data
    // for a new user to be created for them
    if (sections.includes("new_user")) {
      createUser();
      return;
    }
  }

  // checks with the BE whether a user with the given email exists
  const searchUser = () => {
      const validEmail = validateEmail(email)
      handleValidationErrors([[validEmail, INVALID_EMAIL]])

      // do not proceed if validations fail
      if (!validEmail) {
        return;
      }

      setLoading(true);
      let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
      return fetch(`/verifications/user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;",
          "X-CSRF-Token": csrf,
        },
        body: JSON.stringify({
          email: email,
          after_login_path: props.after_login_path
        })
      }).then((res) => {
        if (res.status == 200) {
          res.json().then(json => {
            // if the user exists, BE send an email and the user is prompted 
            // to input the verification code
            setSections([...sections, "verify_code"])
          });
          setLoading(false);
      } else {
        res.json().then(json => {
          // if no user exists, the user is presented with extra fields to fill in 
          // extra data so a new user record can be created
          setSections([...sections, "new_user"])
        });
        setLoading(false);
      }
    })
  };

  // handles user creation and showing the appropriate section
  const createUser = () => {
    const validName = validateName(name);
    const validPhone = validatePhone(phone);
    const validEmail = validateEmail(email);
      
    handleValidationErrors([
      [validEmail, INVALID_EMAIL],
      [validName, INVALID_NAME],
      [validPhone, INVALID_PHONE]
    ]);

    // do not proceed if validations fail
    if (!validEmail || !validName || !validPhone) {
      return;
    }

    setLoading(true);
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
    return fetch(`/u`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        user: {
          email: email,
          name: name,
          phone: phone,
          after_login_path: props.after_login_path
        }
      })
    }).then((res) => {
      if (res.status == 200) {
        res.json().then(json => {
          // once the user is created, BE send a verification and we show
          // the code verification section
          setSections([...sections, "verify_code"])
        });
        setLoading(false);
    } else {
      res.json().then(json => {
      });
      setLoading(false);
    }
  })
  }

  // checks the user code 
  const verifyCode = () => {
    const validCode = validateCode(code)
    handleValidationErrors([[validCode, INVALID_CODE]]);

    if (!validCode) {
      return;
    }

    setLoading(true);
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
    return fetch(`/verifications/code`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        email: email,
        token: code,
        after_login_path: props.after_login_path
      })
    }).then((res) => {
      if (res.status == 200) {
        res.json().then(json => {
          // if the user is signed in, we redirect them to the after sign in path
          window.location.href = json.path
        });
        setLoading(false);
    } if (res.status == 429) {
      res.json().then(json => {
        // since the previous code can no longer be used, we're calling again this function
        // so that a new code can be sent
        searchUser();
        
        handleValidationErrors([
          [true, INVALID_CODE],
          [false, ATTEMPTED_OR_EXPIRED_CODE]
        ])
      });
      setLoading(false);
    } else {
      res.json().then(json => {
        handleValidationErrors([
          [true, ATTEMPTED_OR_EXPIRED_CODE],
          [false, INVALID_CODE]
          ])
      });
      setLoading(false);
    }
  })
  }

  //////////////////////////
  /// TEMPLATE
  //////////////////////////

	return (
        <div className="">
          <label>Имейл</label>
          <input type="text" onChange={(e) => changeEmail(e.target.value.toLowerCase().replace(/ /g,''))} />

          {sections.includes("new_user") ? (
            <div>
              <label>Име и фамилия</label>
              <input type="text" disabled={enabledEditing()} onChange={(e) => setName(e.target.value)} />

              <label>Телефон</label>
              <input type="text" disabled={enabledEditing()} onChange={(e) => setPhone(e.target.value)} />
            </div>
          ) : ("")}

          {sections.includes("verify_code") ? (
            <div>
              <p className="callout success no-margin small" style={{fontSize: "90%"}}><span className="global-weight-bold">Използвай линка за достъп, който изпратихме на попълнения имейл.</span> Валидираме имейла ти за сме сигурни, че няма да изпратим важна или чувствителна информация на друг.</p>
              {/*
              <div className="relative">
                <label>Код за достъп</label>
                <input type="text" maxLength="6" onChange={(e) => setCode(e.target.value)} />
                <span className={`code-guider ${code.length == 6}`}>{code.length}/6</span>
              </div>
              */}
            </div>
          ) : ("")}

          { errors.length > 0 && 
            <div className="callout small-padding mobile-small-font error round">
              <h6>Грешка</h6>
              <ul>
              {errors.map((err) => (
                <li key={Math.random()}>{err}</li>
              ))}
              </ul>
            </div>
          }

          <div>
            {loading ? (
              <button className="button dark-gray large wide no-margin" ><i className="fa-solid fa-loader fa-spin"></i></button>
            ) : (
              <button onClick={(e) => triggerRequest()} className={`button large wide no-margin ${enabledEditing() ? "hidden" : ""}`} >Напред</button>
            )}
          </div>
        </div>

	)
}

export default AccountCheck;
