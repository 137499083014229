import React, { useEffect } from "react";

const LongText = (props) => {
  const { input, formValues, setFormValues } = props;

  useEffect(() => {
    if (!input.required) {
      setFormValues({
        ...formValues,
        [`${input.label_local} (${input.id})`]: "",
      });
    }
  }, []);

  return (
    <div>
      <label>
        {input.label_local}
        {input.required ? "*" : ""}
      </label>
      <textarea
        rows="8"
        name={input.label_local}
        required={input.required}
        onChange={(e) =>
          setFormValues({
            ...formValues,
            [`${input.label_local} (${input.id})`]: e.target.value,
          })
        }
      ></textarea>
    </div>
  );
};

export default LongText;
