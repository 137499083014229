import React, { useState, useRef, useEffect } from "react";

import Slider from "rc-slider";

// Right now works only with static values
const MemeGenerator = (props) => {
  //Images drawn on the canvas (cat image, logo, call to action text)
  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);

  // Canvas state and zoom
  const canvas = useRef(null);
  const canvasContainer = useRef(null);
  const [isCanvasLoading, setCanvasLoading] = useState(false);
  const [zoom, setZoom] = useState(0.5);

  // Text settings
  const [textSize, setTextSize] = useState(50);
  const [textColor, setTextColor] = useState("white");
  const [topText, setTopText] = useState("");
  const [bottomText, setBottomText] = useState("");
  const [bottomTextLineCount, setBottomTextLineCount] = useState(0);

  // Generated Blobs
  const [memeBlob, setMemeBlob] = useState(null);
  const [igMemeBlob, setIgMemeBlob] = useState(null);

  const [isLoading, setLoading] = useState(false);


  const handleOptionSelection = (selectedOption) => {
    setTextColor(selectedOption);
  };

  function download() {
    let canvas = document.getElementById("canvas");
    setLoading(true);
    setCanvasLoading(true);
    addLogo(canvas);

    canvas.toBlob(
      (blob) => {
        // setting blob in state; building IG version and setting it in state
        buildIgMeme(blob);
        setMemeBlob(blob);
      },
      "image/jpeg",
      1
    );

    clearLogo(canvas);
    setCanvasLoading(false);
  }

  const createMeme = (originalMeme, igMeme) => {
    let url = `/koteta/${props.kitten_identifier}/memes`;
    let csrf = document.head.querySelector('meta[name="csrf-token"]').content;
    let formData = new FormData();

    // add generated images to FormData object
    formData.append("image", originalMeme);
    formData.append("ig_image", igMeme);

    //add meme data to FormData object
    formData.append("text_top", topText);
    formData.append("text_bottom", bottomText);
    formData.append("font_size", textSize);
    formData.append("font_color", textColor);

    fetch(url, {
      headers: { "X-CSRF-Token": csrf },
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if ((response.result = "success")) {
          window.location.href = response.redirect_url;
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const buildIgMeme = (blob) => {
    let img = new window.Image();
    img.setAttribute("src", URL.createObjectURL(blob));

    img.onload = () => {
      let ig_canvas = document.getElementById("ig_canvas");
      const ctx = ig_canvas.getContext("2d");

      let backgroundColor = "#FEFEFE";
      let canvasHeight = 1100;
      let canvasWidth = 900;
      let widthScale = 0.99;
      let heightScale = 0.95;
      let marginTop = 70;
      let marginX = (canvasWidth - img.width * widthScale) / 2


      // add white background
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, canvasWidth, canvasHeight);

      // draw a scaled down version of the original Meme
      ctx.drawImage(img, marginX, marginTop, img.width * widthScale, img.height * heightScale)

      // clear border of original Meme
      ctx.lineWidth = 4;
      ctx.strokeStyle = backgroundColor;
      ctx.strokeRect(marginX, marginTop, canvasWidth * widthScale, canvasHeight * heightScale);

      // add new gray border
      ctx.lineWidth = 1;
      ctx.strokeStyle = "#cacaca";
      ctx.strokeRect(0, 0, canvasWidth, canvasHeight);

      ig_canvas.toBlob((blob) => setIgMemeBlob(blob), "image/jpeg", 1);
    }
  }

  useEffect(() => {
    // here we preload the images
    const bottomLeftImage = new Image();
    bottomLeftImage.crossOrigin = "Anonymous";
    bottomLeftImage.src = props.text;
    setImage2(bottomLeftImage);

    const bottomRightImage = new Image();
    bottomRightImage.crossOrigin = "Anonymous";
    bottomRightImage.src = props.logo;

    setImage3(bottomRightImage);
  }, []);

  // Updating the zoom value based on clientWidth
  useEffect(() => {
    if (canvasContainer) {
      setZoom(canvasContainer.current.clientWidth * 0.001 + 0.04);
    }
  }, [canvasContainer]);

  // Making a request to create Meme record once all blobs have been generated
  useEffect(() => {
    if (memeBlob && igMemeBlob) {
      const originalMeme = new File([memeBlob], "meme.png", { type: "image/png" });
      const igMeme = new File([igMemeBlob], "meme.png", { type: "image/png" });

      createMeme(originalMeme, igMeme)
    }

  }, [memeBlob, igMemeBlob])


  // Drawing the cat image, top and bottom texts
  useEffect(() => {
    if (image && canvas) {
      const ctx = canvas.current.getContext("2d");
      // draw outline of the photo (will be changed to white)
      ctx.fillStyle = "#FEFEFE";
      ctx.fillRect(0, 0, 1100, 1100);

      // draw cat image
      ctx.drawImage(image, (356 - 256) / 2, 45);

      // define text size,font, position and color
      ctx.font = `900 ${textSize}px Montserrat`;
      ctx.fillStyle = textColor;
      ctx.textAlign = "center";

      // draw the text
      let maxWidth = 750;
      let lineHeight = parseInt(textSize) + 5;
      let topTextY = 50 + lineHeight;
      let bottomTextY = 920 - bottomTextLineCount * lineHeight;
      wrapTopText(ctx, topText, 900 / 2, topTextY, maxWidth, lineHeight);
      wrapBottomText(
        ctx,
        bottomText,
        900 / 2,
        bottomTextY,
        maxWidth,
        lineHeight
      );

      ctx.lineWidth = 1;
      ctx.strokeStyle = "#cacaca";
      ctx.strokeRect(0, 0, 900, 1100);
    }
  }, [image, image2, image3, canvas, topText, bottomText, textColor, textSize]);

  const createAndSetNewImage = (url) => {
    const catImage = new Image();
    let rand = "?" + Math.random();
    catImage.crossOrigin = "anonymous";
    catImage.src = url + rand;

    catImage.onload = () => setImage(catImage);
  };

  const addLogo = (canvas) => {
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image2,
      (356 - 256) / 2,
      980,
      image2.width / 2.75,
      image2.height / 2.75
    );
    ctx.drawImage(
      image3,
      1076 / 2,
      980,
      image3.width / 1.95,
      image3.height / 1.95
    );
  };

  const clearLogo = (canvas) => {
    const ctx = canvas.getContext("2d");

    // rewriting logo images with white background
    // we could make them transparent with clearRect instead
    ctx.fillStyle = "#FEFEFE";
    ctx.fillRect(0, 970, canvas.width, canvas.height);
    ctx.fillRect(0, 970, canvas.width, canvas.height);

    // drawing the border again
    // we could add the border outside the canvas
    // to avoid drawing it
    ctx.lineWidth = 1;
    ctx.strokeStyle = "#cacaca";
    ctx.strokeRect(0, 0, 900, 1100);
  };

  const wrapTopText = (context, text, x, y, maxWidth, lineHeight) => {
    wrapText(context, text, x, y, maxWidth, lineHeight);
  };

  const wrapBottomText = (context, text, x, y, maxWidth, lineHeight) => {
    let lines_count = wrapText(context, text, x, y, maxWidth, lineHeight);
    setBottomTextLineCount(lines_count);
  };

  const wrapText = (context, text, x, y, maxWidth, lineHeight) => {
    let words = text.split(" ");
    let line = "";
    let lines_count = 0;

    for (let n = 0; n < words.length; n++) {
      let testLine = line + words[n] + " ";
      let metrics = context.measureText(testLine);
      let testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        lines_count += 1;
        context.fillText(line, x, y, maxWidth);
        line = words[n] + " ";
        y += lineHeight;
      } else {
        line = testLine;
      }
    }

    context.fillText(line, x, y, maxWidth);
    return lines_count;
  };

  const onSliderChange = (selectedValue) => {
    setTextSize(selectedValue);
  };

  const [showCyrillicErrorTop, setShowCyrillicErrorTop] = useState(false);
  const [showCyrillicErrorBottom, setShowCyrillicErrorBottom] = useState(false);

  const onTextChange = (value, textPosition) => {
    const lastTypedLetter = value[value.length - 1] || "";
    const regex = /[\u0400-\u04FF0-9 ]/gi; // regex for cyrillic letters
    const isCyrillic = regex.test(lastTypedLetter);

    if (isCyrillic || lastTypedLetter === "") {
      if (textPosition === "top") {
        setTopText(value);
      } else {
        setBottomText(value);
      }
      setShowCyrillicErrorTop(false);
      setShowCyrillicErrorBottom(false);
    } else {
      if (textPosition === "top") {
        setShowCyrillicErrorTop(true);
      } else {
        setShowCyrillicErrorBottom(true);
      }
    }
  };

  useEffect(() => {
    // show errors for 2 secs if needed
    if (showCyrillicErrorTop) {
      const timer = setTimeout(() => {
        setShowCyrillicErrorTop(false);
      }, 2000);
      return () => clearTimeout(timer);
    }

    if (showCyrillicErrorBottom) {
      const timer = setTimeout(() => {
        setShowCyrillicErrorBottom(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showCyrillicErrorTop, showCyrillicErrorBottom]);

  return (
    <div className="grid-x grid-margin-x">
      <div className="cell medium-6" ref={canvasContainer}>
        <h4 className="hide-for-small-only">1. Избери снимка</h4>
        {isCanvasLoading ? (
          "..."
        ) : (
          <div style={{ zoom: zoom }}>
            <canvas id="canvas" ref={canvas} width={900} height={1100} />
          </div>
        )}

        {/* Hidden canvas element used to build IG version of meme*/}
        <canvas id="ig_canvas" style={{display: "none" }} width={900} height={1100} />
      </div>
      <div className="cell medium-2">
        <div className="hide-for-small-only">        
          <br /> <br />
        </div>
        {props.images.map((img, index) => (
            <img
              key={index}
              crossOrigin="anonymous"
              src={img}
              className="radius gallery-thumb meme-thumb"
              onLoad={() => {
                if (index == 0 && !image) {
                  createAndSetNewImage(img);
                }
              }}
              onClick={() => createAndSetNewImage(img)}
            />
        ))}
      </div>

      <div className="cell medium-4">        
        <div className="show-for-small-only">        
          <br />
        </div>
        <h4>2. Попълни текст</h4>
        
        <label>
          Горен текст{" "}
          {showCyrillicErrorTop && <span style={{ color: "red" }}>(кирилица)</span>}
        </label>
        <input
          type="text"
          value={topText}
          onChange={(e) => onTextChange(e.target.value, "top")}
          maxLength={50}
        />

        <label>
          Долен текст{" "}
          {showCyrillicErrorBottom && (
            <span style={{ color: "red" }}>(кирилица)</span>
          )}
        </label>
        <input
          type="text"
          value={bottomText}
          onChange={(e) => onTextChange(e.target.value, "bottom")}
          maxLength={50}
        />

        <div className="grid-x">
          <div className="cell small-6">
            <label>Цвят на текст</label>
            <div className="kitten-color-picker">
              <a
                className={`kitten-color-picker--option white ${
                  textColor === "white" ? "selected" : ""
                }`}
                onClick={() => handleOptionSelection("white")}
              >
                <i className="fa fa-check"></i>
              </a>
              <a
                className={`kitten-color-picker--option black ${
                  textColor === "black" ? "selected" : ""
                }`}
                onClick={() => handleOptionSelection("black")}
              >
                <i className="fa fa-check"></i>
              </a>
            </div>
            <input type="hidden" value={textColor} name="kitten[colors]" />
          </div>

          <div className="cell small-6">
            <label>Размер на текст</label>
            <div style={{ maxWidth: 120, marginTop: 19 }}>
              <Slider
                railStyle={{ backgroundColor: "#E6E6E6", height: "8px" }}
                trackStyle={{ backgroundColor: "#3A556F", height: "8px" }}
                handleStyle={{
                  backgroundColor: "#FC8983",
                  height: "19px",
                  width: "19px",
                  opacity: 1,
                  outline: "none",
                  border: "none",
                  boxShadow: "none",
                }}
                value={textSize}
                min={50}
                max={80}
                step={1}
                onChange={onSliderChange}
              />
            </div>
          </div>
        </div>
        <br />
        <h4>3. Генерирай своя мийм</h4>
        {isLoading ? (
          <div className="clock-loader"></div>
        ) : (
          <button
            className="button"
            onClick={() => download()}
            disabled={topText.trim() == "" && bottomText.trim() == ""}
          >
            Генерирай
          </button>
        )}
      </div>
    </div>
  );
};

export default MemeGenerator;
