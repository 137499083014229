import React from "react";
import i18n from "../i18n";
import Address from "../shared/Address.jsx";

export default class PaymentAddressOld extends React.Component {
  constructor(props) {
    super(props);

    this.emailError = i18n.t("email_error");
    this.phoneError = i18n.t("phone_error");

    this.state = {
      isDisabled: true,
      isEditing: true,
      email: this.props.email || "",
      phone: this.props.phone || "",
      isChecked: false,
      address: {
        street: "",
        street_number: "",
        unit: "",
        city: "",
      },
      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.updateState = this.updateState.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
  }

  componentDidMount() {
    PubSub.subscribe("quantity_editing_state", this.updateState);
  }

  updateState(e, state) {
    this.setState({
      isDisabled: state,
    });
  }

  handleChange(e) {
    console.log(e);
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleAddressChange(addressChanges) {
    let newAddress = { ...this.state.address, ...addressChanges };
    this.setState({ address: newAddress });
  }

  finalizeAddress(state) {
    this.setState({ isEditing: state });

    PubSub.publish("address_editing_state", state);
    PubSub.publish("updated_delivery_info", {
      email: this.state.email,
      phone: this.state.phone,
      address: this.state.address,
    });
  }

  /*
   FORM VALIDATION
  */

  verifyFormField(field) {
    switch (field) {
      case "email":
        return this.verifyEmail();
      case "phone":
        return this.verifyPhone();
      default:
        return false;
    }
  }

  verifyEmail() {
    let re = /\S+@\S+\.\S+/;
    let result = re.test(this.state.email);

    result
      ? this.clearError("email")
      : this.addError({ email: this.emailError });

    return !!result;
  }

  verifyPhone() {
    let re = /(\+3598|08)\d{8}/;
    let result = re.test(this.state.phone);

    result
      ? this.clearError("phone")
      : this.addError({ phone: this.phoneError });

    return !!result;
  }

  addError(error) {
    this.setState({ errors: { ...this.state.errors, ...error } });
  }

  clearError(field) {
    this.setState({ errors: { ...this.state.errors, ...{ [field]: "" } } });
  }

  render() {
    let { isDisabled, isEditing } = this.state;

    if (isDisabled) {
      return (
        <div>
          <p>{i18n.t("inactive_address_text")}</p>
        </div>
      );
    }

    if (!isEditing) {
      return (
        <div className="grid-x">
          <div className="cell medium-5">{this.state.email}</div>

          <div className="cell medium-5 medium-offset-2">
            {this.state.phone}
          </div>

          <div className="cell medium-12">
            {Object.values(this.state.address).join(", ")}
          </div>
          <div className="cell medium-5 medium-offset-3">
            <a
              href="/"
              className="button wide hollow"
              onClick={(e) => {
                e.preventDefault(e);
                this.finalizeAddress(true);
              }}
            >
              {" "}
              Rедактирай
            </a>
          </div>
        </div>
      );
    }
    return (
      <div>
        <label htmlFor="email">{i18n.t("email")}</label>
        {this.state.errors.email}
        <input
          type="email"
          required={true}
          name="email"
          placeholder="user@example.com"
          value={this.state.email}
          autoFocus="autofocus"
          onChange={this.handleChange}
          onBlur={() => this.verifyFormField("email")}
        />

        <label htmlFor="phone">{i18n.t("phone")}</label>
        {this.state.errors.phone}
        <input
          type="tel"
          required={true}
          name="phone"
          placeholder="+359 888 7777 66"
          value={this.state.phone}
          onChange={this.handleChange}
          onBlur={() => this.verifyFormField("phone")}
        />

        <Address
          title="Адрес"
          handleAddressChange={this.handleAddressChange}
          address={this.state.address}
          errors={this.state.errors}
        />

        <input
          type="checkbox"
          name="checkbox"
          id="checkbox"
          onChange={() => this.setState({ isChecked: !this.state.isChecked })}
          checked={this.state.isChecked}
        />
        <label htmlFor="checkbox">Terms and Conditions</label>

        <div className="cell">
          <a
            href="/"
            className="button wide"
            onClick={(e) => {
              e.preventDefault(e);
              this.finalizeAddress(false);
            }}
          >
            {" "}
            Към Плащане
          </a>
        </div>
      </div>
    );
  }
}
