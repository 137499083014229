import React from "react";
import i18n from "../i18n";

export default class KeyPickUp extends React.Component {
  render() {
    let { pickUpDate, pickUpHour, dropOffDate, dropOffHour } = this.props;

    return (
      <div className="grid-x margin-x">
        <div className="cell medium-12">
          <h4>{i18n.t("booking_key_pickup_title")}</h4>
        </div>

        <div className="cell medium-12">
          <div>{this.props.errors.pickUpStartDateConflict}</div>
          <div>{this.props.errors.pickUpHour}</div>
        </div>
        <div className="cell medium-5">
          <label>{i18n.t("pickup_date")}</label>
          <input
            type="date"
            name="pickUpDate"
            placeholder="dd/mm/yyyy"
            value={pickUpDate}
            onChange={this.props.handleChange}
          />
        </div>
        <div className="cell medium-3">
          <label>{i18n.t("pickup_hour")}</label>
          <input
            type="time"
            name="pickUpHour"
            placeholder="hh:mm"
            value={pickUpHour}
            onChange={this.props.handleChange}
          />
        </div>

        <div className="cell medium-12">
          <div>{this.props.errors.dropOffEndDateConflict}</div>
          <div>{this.props.errors.dropOffHour}</div>
        </div>
        <div className="cell medium-5">
          <label>{i18n.t("dropoff_date")}</label>
          <input
            type="date"
            name="dropOffDate"
            placeholder="dd/mm/yyyy"
            value={dropOffDate}
            onChange={this.props.handleChange}
          />
        </div>
        <div className="cell medium-3">
          <label>{i18n.t("dropoff_hour")}</label>
          <input
            type="time"
            name="dropOffHour"
            placeholder="hh:mm"
            value={dropOffHour}
            onChange={this.props.handleChange}
          />
        </div>
      </div>
    );
  }
}
