import React, { useState } from "react";
import KittenCard from "../kittens/KittenCard.jsx";
import i18n from "../i18n";

const SimpleKittenList = (props) => {
	const { locale, kittens } = props;

	// this is needed so that it loads the right
	const locale_path = locale == 'de' ? "/de/" : "/";

  return (
		<div className="grid-x grid-margin-x align-middle" id="kitten-list-grid">
			{kittens.map((kitten, index) => <KittenCard kitten={JSON.parse(kitten)} locale={locale} locale_path={locale_path} key={index} />)}
		</div>
  );
};

export default SimpleKittenList;
